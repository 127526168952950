import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import moment from "moment";
import { Text } from "components/Typography/text.styled";
import { CompanyCalendarGigDto, CompanyCalendarShift } from "model/Calendar";
import { ShiftCell } from "../weeklyCalendar.styled";
import { OfferedGigModal } from "../offeredGigModal";

type Props = {
  name: string;
  compactView: boolean;
  shift: CompanyCalendarShift;
  keyId: number;
  dashboard: boolean | undefined;
  gigData: CompanyCalendarGigDto;
  deleteShift: any;
  workerDataId: number;
};

export const OfferedCalendarGig: React.FC<Props> = ({
  name,
  compactView,
  shift,
  keyId,
  dashboard,
  gigData,
  workerDataId,
}) => {
  const { t } = useTranslation();
  const [offeredModalOpen, setOfferedModalOpen] = useState(false);
  const tooltipContent = compactView
    ? `${moment(shift.startTime).format("HH:mm") ?? ""} - ${
        moment(shift.endTime).format("HH:mm") ?? ""
      }`
    : name;

  return (
    <>
      <ShiftCell
        key={keyId}
        backgroundColor={Color.LighterYellow}
        data-tooltip-id={"worker-name-offered"}
        data-tooltip-content={tooltipContent}
        onClick={() => {
          setOfferedModalOpen(true);
        }}
      >
        {!compactView ? (
          <Text color={Color.White}>
            {`${moment(shift.startTime).format("HH:mm")} - ${moment(
              shift.endTime
            ).format("HH:mm")}`}
          </Text>
        ) : (
          <Text fontSize={FontSize.Small} color={Color.White}>{`${
            name.length > 6 && dashboard
              ? `${name.slice(0, 6)}...`
              : name
              ? `${name}`
              : dashboard && !name
              ? t(t("CalendarCompany.NotFilled")).slice(0, 7) + "..."
              : t(t("CalendarCompany.NotFilled"))
          }`}</Text>
        )}
        {!compactView ? (
          <Text color={Color.White}>{tooltipContent}</Text>
        ) : (
          <Tooltip
            id={"worker-name"}
            place="top"
            style={{
              backgroundColor: Color.White,
              color: Color.MidnightBlue,
              fontFamily: FontFamily.MontserratRegular,
              boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.1)",
            }}
          />
        )}
      </ShiftCell>
      {offeredModalOpen && (
        <OfferedGigModal
          offeredModalOpen={offeredModalOpen}
          setOfferedModalOpen={setOfferedModalOpen}
          offeredWorkerName={name}
          shiftData={gigData}
          offeredWorkerId={workerDataId}
        />
      )}
    </>
  );
};
