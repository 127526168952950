import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";
import { ApplicationDto, ApplicationRequestDto } from "model/Application";

const getGigApplications = async (
  companyId: number,
  gigId: number,
  gigShiftId?: number
): Promise<AxiosResponse<ApplicationDto[]>> => {
  let url = `${API_ROOT}/companies/${companyId}/gigs/${gigId}/applications?documents=false`;

  if (gigShiftId !== undefined) {
    url += `&gigShiftId=${gigShiftId}`;
  }

  return await axiosRequest.auth
    .get<ApplicationDto[]>(url)
    .then((res) => {
      return res;
    });
};

const updateApplicationStatus = async (
  companyId: number,
  applicationId: number,
  applicationData: ApplicationRequestDto
): Promise<AxiosResponse<ApplicationDto>> => {
  return await axiosRequest.auth.put<ApplicationDto>(
    `${API_ROOT}/companies/${companyId}/applications/${applicationId}/status`,
    applicationData
  );
};

const getApplicationById = async (
  companyId: number,
  applicationId: number
): Promise<AxiosResponse<ApplicationDto>> => {
  return await axiosRequest.auth.get<ApplicationDto>(
    `${API_ROOT}/companies/${companyId}/applications/${applicationId}`
  );
};

const getPaginatedApplications = async (
  companyId: number,
  page: number,
  amount: number,
  filter: string
): Promise<AxiosResponse<ApplicationDto[]>> => {
  return await axiosRequest.auth
    .get<ApplicationDto[]>(
      `${API_ROOT}/companies/${companyId}/applications/v2?page=${page}&amount=${amount}&filter=${filter}&includeHidden=false`
    )
    .then((res) => {
      return res;
    });
};

const getPaginatedApplicationsPending = async (
  companyId: number,
  page: number,
  amount: number
): Promise<AxiosResponse<ApplicationDto[]>> => {
  return await axiosRequest.auth
    .get<ApplicationDto[]>(
      `${API_ROOT}/companies/${companyId}/applications/v2?status=Pending&page=${page}&amount=${amount}`
    )
    .then((res) => {
      return res;
    });
};

const getAllPaginatedApplications = async (
  companyId: number,
  page: number,
  amount: number
): Promise<AxiosResponse<ApplicationDto[]>> => {
  return await axiosRequest.auth
    .get<ApplicationDto[]>(
      `${API_ROOT}/companies/${companyId}/applications/v2?page=${page}&amount=${amount}&includeHidden=false`
    )
    .then((res) => {
      return res;
    });
};

const getPendingApplicationsCount = async (
  companyId: number
): Promise<AxiosResponse<ApplicationDto[]>> => {
  return await axiosRequest.auth
    .get<ApplicationDto[]>(
      `${API_ROOT}/companies/${companyId}/applications/v2/count`
    )
    .then((res) => {
      return res;
    });
};

export const application = {
  updateApplicationStatus,
  getApplicationById,
  getGigApplications,
  getPaginatedApplications,
  getPendingApplicationsCount,
  getPaginatedApplicationsPending,
  getAllPaginatedApplications
};
