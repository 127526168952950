import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import {
  ApplicationDto,
  ApplicationResolution,
  ApplicationStatus,
  UserApplicationDto,
} from "model/Application";
import { Color } from "config/colors";
import { ApplicationListItem } from "./applicationListItem.component";
import { H3, Text } from "components/Typography/text.styled";
import { ListContainer, ListWrapper, Row } from "./applicationList.styled";
import { ShiftDto } from "model/Shift";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Api } from "services/api/api.service";
import { FontFamily, FontSize } from "config/font";
import { Checkbox, Modal } from "@material-ui/core";
import {
  ButtonContainer,
  ContractModalBody,
  ModalBody,
} from "../gigApplicants/gigApplicants.styled";
import { GigContract } from "../gigApplicants/gigContract.component";
import { useWindowScrollProgress } from "hooks/useWindowScrollProgress";
import { LoaderProgress } from "components/icon/loaderProgress.component";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useAlertStore } from "stores/alertStore/alertStore";
import { LabelStyled, TextArea } from "components/form/inputs/inputs.styled";
import { ButtonsContainer } from "../gigDetail/gigDetail.styled";
import {
  CalendarViewText,
  CreateGigModalBody,
} from "../calendar/weeklyCalendar.styled";
import { SaveGigForm } from "../saveGig/saveGigForm/saveGigForm.component";
import { GigSummaryModal } from "../gigDetail/gigSummaryModal.component";
import {
  CheckBoxContainer,
  CheckboxText,
  FilterContainer,
} from "../gig/gigs.styled";

export type ApplicationGig = {
  id: number;
  role: string;
  amountOfWorkers: number;
  numberOfApplicants: number;
  acceptedWorkers: number;
  shifts: ShiftDto[];
  worker: UserApplicationDto;
  application: ApplicationDto;
};

export const ApplicationList: React.FC = () => {
  const { t } = useTranslation();
  const [applicationState, applicationActions, applicationDispatch] =
    useApplicationStore();
  const [, , alertDispatch] = useAlertStore();
  const [companyState] = useCompanyStore();
  const [status, setStatus] = useState<"Pending" | "All">("Pending");
  const [filter, setFilter] = useState<
    "All" | "Accepted" | "Declined" | "Withdrawn" | "Offered"
  >("All");
  const [activeApplication, setActiveApplication] = useState<number | null>(
    null
  );
  const [selectedApplications, setSelectedApplications] = useState<
    ApplicationDto[]
  >([]);
  const [selectApplications, setSelectApplications] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [declineReason, setDeclineReason] = useState("");

  const [applications, setApplications] = useState<ApplicationDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const apiState = useRef({ loading: false, completed: false, page: 0 });
  const [showCreateGigModal, setShowCreateGigModal] = useState(false);
  const [gigSummaryModalOpen, setGigSummaryModalOpen] = useState(false);
  const [createdGigId, setCreatedGigId] = useState<number | null>(null);

  const handleShowContract = (application: ApplicationDto) => {
    setActiveApplication(application.id);
  };

  const fetchApplications = () => {
    if (
      !companyState.company ||
      apiState.current.loading ||
      apiState.current.completed
    )
      return;

    setIsLoading(true);
    apiState.current.loading = true;
    // Get an aproximation of cells to load for each API call
    const cellHeight = 56;
    const amount = Math.ceil(window.innerHeight / cellHeight);

    const endPoint =
      status === "Pending"
        ? Api().company.application.getPaginatedApplicationsPending(
            companyState.company.id,
            apiState.current.page,
            amount
          )
        : filter == "All"
        ? Api().company.application.getAllPaginatedApplications(
            companyState.company.id,
            apiState.current.page,
            amount
          )
        : Api().company.application.getPaginatedApplications(
            companyState.company.id,
            apiState.current.page,
            amount,
            filter
          );

    endPoint
      .then((res) => {
        setApplications((old) => [...old, ...res.data]);

        if (res.data.length >= amount) {
          apiState.current.page++;
        } else {
          apiState.current.completed = true;
        }
      })
      .finally(() => {
        setIsLoading(false);
        apiState.current.loading = false;
      });
  };

  const fetchApplicationCount = () => {
    if (companyState.company?.id) {
      Api()
        .company.application.getPendingApplicationsCount(
          companyState.company?.id
        )
        .then((res: any) => {
          applicationDispatch({
            type: "GET_PENDING_APPLICATIONS_COUNT",
            payload: res.data.value,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    setApplications([]);
    apiState.current = { loading: false, completed: false, page: 0 };
    fetchApplications();
    fetchApplicationCount();
  }, [status, companyState.company, filter]);

  useWindowScrollProgress(fetchApplications);

  const updateMarkedApplications = (
    status: ApplicationStatus.CLOSED | ApplicationStatus.OFFERED
  ) => {
    let errors = 0;
    setIsLoading(true);
    Promise.all(
      selectedApplications.map(async (application) => {
        if (companyState.company) {
          const data = {
            ...application,
            status,
            ...(status === ApplicationStatus.CLOSED && {
              resolution: ApplicationResolution.REJECTED,
            }),
            declineReason,
          };
          applicationActions
            .updateApplicationStatus(
              companyState.company.id,
              application.id,
              data
            )
            .catch((err) => (errors += 1));
        }
      })
    )
      .then(() => {
        applicationDispatch({
          type: "GET_PENDING_APPLICATIONS_COUNT",
          payload:
            applicationState.applicationsCount - selectedApplications.length,
        });
        if (status === ApplicationStatus.OFFERED) {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark",
              title: t("ApplicationsCompany.Offered"),
              message: t("ApplicationsCompany.ApplicantHasBeenOfferedGig"),
            },
          });
        } else {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark",
              title: t("ApplicationsCompany.Rejected"),
              message: t("ApplicationsCompany.ApplicationHasBeenRejected"),
            },
          });
        }
      })
      .catch(() => {
        alertDispatch({
          type: "SHOW_ALERT",
          payload: {
            icon: "alert",
            title: t("Alerts.OhNo"),
            message: t("ApplicationsCompany.ApplicationCouldNotBeUpdated"),
          },
        });
      });
  };

  return (
    <>
      <ButtonStyled
        color={Color.White}
        backgroundColor={Color.ModernGreen}
        onClick={() => setShowCreateGigModal(true)}
        style={{ marginLeft: 0, marginBottom: 40 }}
      >
        {t("ApplicationsCompany.RecruitWorkers")}
      </ButtonStyled>
      <FilterContainer>
        <CheckBoxContainer>
          <Checkbox
            checked={status === "Pending"}
            onChange={() => setStatus("Pending")}
          />
          <CheckboxText>
            {t("ApplicationsCompany.NewApplications")}{" "}
            <Text
              style={{
                padding: 12,
                backgroundColor: Color.DarkerChampagnePink,
                borderRadius: 50,
                width: 20,
                height: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                marginLeft: 6,
                marginRight: 10,
                color: Color.White,
                fontFamily: FontFamily.MontserratSemiBold,
              }}
            >
              {applicationState.applicationsCount}
            </Text>
          </CheckboxText>
        </CheckBoxContainer>
        <CheckBoxContainer>
          <Checkbox
            checked={status === "All"}
            onChange={() => setStatus("All")}
          />
          <CheckboxText>{t("GigsCompany.All")}</CheckboxText>
        </CheckBoxContainer>
      </FilterContainer>
      <Text
        fontFamily={FontFamily.MontserratSemiBold}
        fontSize={FontSize.Standard}
      >
        {t("ApplicationsCompany.Filter")}
      </Text>
      {status == "All" && (
        <FilterContainer style={{ margin: 10 }}>
          <CheckBoxContainer>
            <Checkbox
              checked={filter === "All"}
              onChange={() => setFilter("All")}
            />
            <CheckboxText>{t("GigsCompany.All")}</CheckboxText>
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              checked={filter === "Accepted"}
              onChange={() => setFilter("Accepted")}
            />
            <CheckboxText>{t("GigsCompany.Accepted")}</CheckboxText>
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              checked={filter === "Declined"}
              onChange={() => setFilter("Declined")}
            />
            <CheckboxText>{t("GigsCompany.Declined")}</CheckboxText>
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              checked={filter === "Withdrawn"}
              onChange={() => setFilter("Withdrawn")}
            />
            <CheckboxText>{t("GigsCompany.Withdrawn")}</CheckboxText>
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Checkbox
              checked={filter === "Offered"}
              onChange={() => setFilter("Offered")}
            />
            <CheckboxText>{t("GigsCompany.Offered")}</CheckboxText>
          </CheckBoxContainer>
        </FilterContainer>
      )}
      <Row>
        <Text
          style={{ marginLeft: "4px" }}
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.Large}
        >
          {t("ApplicationsCompany.Application").charAt(0).toUpperCase() +
            t("ApplicationsCompany.Application").slice(1)}
        </Text>
        <Text
          style={{ marginLeft: "4px" }}
          fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.Large}
        >
          {t("General.Assignment")}
        </Text>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{ marginLeft: "4px" }}
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("General.Options")}
          </Text>

          <CalendarViewText
            active={selectApplications}
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Standard}
            onClick={() => setSelectApplications(!selectApplications)}
          >
            {t("ApplicationsCompany.SelectMultiple")}
          </CalendarViewText>
        </div>
      </Row>

      <ListContainer>
        <ListWrapper loading={isLoading}>
          {applications.map((app, index) => {
            return status === "Pending" ? (
              <ApplicationListItem
                key={index}
                application={app}
                handleShowContract={handleShowContract}
                selectApplications={selectApplications}
                selectedApplications={selectedApplications}
                setSelectedAppliations={setSelectedApplications}
                pending
              />
            ) : (
              <ApplicationListItem
                key={index}
                application={app}
                handleShowContract={handleShowContract}
                selectApplications={selectApplications}
                selectedApplications={selectedApplications}
                setSelectedAppliations={setSelectedApplications}
              />
            );
          })}
          {!applications.length && !isLoading && (
            <Text color={Color.SeaBlue400}>
              {t("ApplicationsCompany.YouHaveNoApplications")}
            </Text>
          )}
        </ListWrapper>
        {selectApplications && (
          <ButtonsContainer style={{ marginTop: "20px" }}>
            <ButtonStyled
              disabled={selectedApplications.length === 0}
              onClick={() =>
                updateMarkedApplications(ApplicationStatus.OFFERED)
              }
            >
              {`${t("GigsCompany.SendOffer")}${
                selectedApplications.length > 0
                  ? " (" + selectedApplications.length + ")"
                  : ""
              }`}
            </ButtonStyled>
            <ButtonStyled
              backgroundColor={Color.Destructive}
              disabled={selectedApplications.length === 0}
              onClick={() => setShowDeclineModal(true)}
            >
              {`${t("GigsCompany.Reject")} ${
                selectedApplications.length > 0
                  ? " (" + selectedApplications.length + ")"
                  : ""
              }`}
            </ButtonStyled>
          </ButtonsContainer>
        )}
      </ListContainer>
      <Modal
        open={activeApplication !== null}
        onClose={() => {
          setActiveApplication(null);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ContractModalBody>
          <GigContract
            applicationId={activeApplication}
            closeContract={() => setActiveApplication(null)}
          />
        </ContractModalBody>
      </Modal>
      <Modal
        open={activeApplication !== null}
        onClose={() => {
          setActiveApplication(null);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ContractModalBody>
          <GigContract
            applicationId={activeApplication}
            closeContract={() => setActiveApplication(null)}
          />
        </ContractModalBody>
      </Modal>

      <Modal
        open={activeApplication !== null}
        onClose={() => {
          setActiveApplication(null);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ContractModalBody>
          <GigContract
            applicationId={activeApplication}
            closeContract={() => setActiveApplication(null)}
          />
        </ContractModalBody>
      </Modal>
      <Modal
        open={showDeclineModal}
        onClose={() => {
          setShowDeclineModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody>
          <Text
            align="center"
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {selectedApplications.length > 1
              ? t("GigsCompany.YouAreAboutToRejectApplications", {
                  number: selectedApplications.length,
                })
              : t("GigsCompany.YouAreAboutToReject1Application")}
          </Text>
          <Text fontSize={FontSize.Large} color={Color.SeaBlue600}>
            {t("GigsCompany.ThisCannotBeUndone")}
          </Text>
          <LabelStyled>{t("GigsCompany.NoteToApplicant")}</LabelStyled>
          <TextArea
            placeholder={t("GigsCompany.SendANote")}
            rows={5}
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
          />
          <ButtonContainer>
            <ButtonStyled
              backgroundColor={Color.Disabled}
              color={Color.SeaBlue500}
              onClick={() => {
                setShowDeclineModal(false);
              }}
            >
              {t("GigsCompany.Discard")}
            </ButtonStyled>
            <ButtonStyled
              onClick={() => {
                updateMarkedApplications(ApplicationStatus.CLOSED);
              }}
              backgroundColor={Color.Destructive}
            >
              {t("GigsCompany.Reject")}
            </ButtonStyled>
          </ButtonContainer>
        </ModalBody>
      </Modal>

      {isLoading && <LoaderProgress />}
      {showCreateGigModal && (
        <Modal
          open={showCreateGigModal}
          onClose={() => {
            setShowCreateGigModal(false);
          }}
        >
          <CreateGigModalBody>
            <H3>{t("ApplicationsCompany.RecruitWorkers")}</H3>
            <SaveGigForm
              setShowCreateGigModal={setShowCreateGigModal}
              onCreateSuccess={(gigId: number) => {
                setShowCreateGigModal(false);
                setCreatedGigId(gigId);
                setGigSummaryModalOpen(true);
              }}
              recruit
            />
          </CreateGigModalBody>
        </Modal>
      )}
      {gigSummaryModalOpen && (
        <GigSummaryModal
          gigId={createdGigId}
          isOpen={gigSummaryModalOpen}
          onClose={() => setGigSummaryModalOpen(false)}
        />
      )}
    </>
  );
};
