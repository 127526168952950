import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CompanyGigDto, GigRating } from "model/Gig";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import {
  RatingContainer,
  RatingRow,
  Row,
  DropDownRow,
  VerticalDivider,
  RatingCell,
  GigInfoContainer,
} from "./gigDetail.styled";
import { Api } from "services/api/api.service";
import {
  IconCircularContainer,
  IconsContainer,
} from "../dashboard/dashboard.styled";

type Props = {
  gigData: CompanyGigDto;
  isModalOpen: boolean;
};

export const GigDetailRatings: React.FC<Props> = ({ gigData, isModalOpen }) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [ratings, setRatings] = useState<GigRating[]>([]);
  const [ratingsOpen, setRatingsOpen] = useState(isModalOpen);
  const [showMessage, setShowMessage] = useState(false);
  const [hoveredMessage, setHoveredMessage] = useState<number | undefined>();

  useEffect(() => {
    if (gigData && companyState.company?.id) {
      Api()
        .company.gig.getGigRating(companyState.company?.id, gigData.id)
        .then((res) => {
          setRatings(res.data);
        })
        .catch((err) => {
          console.log("ERROR fetching rating for gig", err);
        });
    }
  }, [gigData]);

  return (
    <>
      {ratings.length !== 0 && (
        <>
          {!isModalOpen ? (
            <DropDownRow
              onClick={() => {
                setRatingsOpen(!ratingsOpen);
              }}
            >
              <Text fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Large}>
                {t("GigsCompany.Ratings")}
              </Text>
              <CustomIcon
                name={ratingsOpen ? "upward_arrow" : "downward_arrow"}
                size="24px"
                color={Color.BurntSienna}
                style={{ marginTop: 10 }}
              />
            </DropDownRow>
          ) : (
            <Row>
              <Text fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Large}>
                {t("GigsCompany.Ratings")}
              </Text>
            </Row>
          )}
          {ratingsOpen && (
            <RatingContainer>
              {ratings.map((rating, index) => (
                <>
                  <RatingCell>
                    <GigInfoContainer>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                        fontSize={FontSize.Standard}
                        style={{ margin: 0, padding: 0 }}
                      >
                        {`${rating.firstName} ${rating.lastName}`}
                      </Text>
                      <RatingRow>
                        <Text>{rating.rating + "/5"}</Text>
                        <CustomIcon
                          style={{ zIndex: 1000 }}
                          name="star"
                          size="24px"
                          padding="4px"
                          color={Color.LighterYellow}
                          onMouseEnter={() => {
                            setShowMessage(true);
                            setHoveredMessage(index);
                          }}
                          onMouseLeave={() => {
                            setShowMessage(false);
                            setHoveredMessage(undefined);
                          }}
                        />
                      </RatingRow>
                    </GigInfoContainer>
                    <VerticalDivider />
                    <IconsContainer>
                      <IconCircularContainer
                        isAccept
                        isDisabled={rating.message == ""}
                      >
                        <CustomIcon
                          style={{ zIndex: 1000 }}
                          name="message"
                          size="24px"
                          padding="2px"
                          color={Color.White}
                          onMouseEnter={() => {
                            setShowMessage(true);
                            setHoveredMessage(index);
                          }}
                          onMouseLeave={() => {
                            setShowMessage(false);
                            setHoveredMessage(undefined);
                          }}
                        />
                      </IconCircularContainer>
                    </IconsContainer>
                  </RatingCell>
                  {showMessage && index == hoveredMessage && rating.message && (
                    <RatingCell>
                      <Text
                        style={{ margin: 0, padding: 0 }}
                        fontSize={FontSize.Small}
                        color={Color.SeaBlue600}
                      >
                        {t("General.Message")}
                      </Text>
                      <Text
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratRegular}
                      >
                        {rating.message}
                      </Text>
                    </RatingCell>
                  )}
                </>
              ))}
            </RatingContainer>
          )}
        </>
      )}
    </>
  );
};
