import { TimeReportResolution, TimeReportStatus } from "./TimeReport";
import { ApplicationResolution, ApplicationStatus } from "./Application";
import { GigResolution, GigStatus, GigType } from "./Gig";

export type CompanyCalendarApplication = {
  id: number;
  status: ApplicationStatus;
  worker: CompanyCalendarWorker;
  resolution: ApplicationResolution;
};

type CompanyCalendarWorker = {
  id: number;
  firstName: string;
  lastName: string;
};

export type CompanyCalendarShift = {
  id: number;
  startTime: string;
  endTime: string;
  timeReports: CompanyCalendarTimereport[];
};

export type CompanyCalendarTimereport = {
  id: number;
  workerId: number;
  startTime: string;
  endTime: string;
  status: TimeReportStatus;
  resolution: TimeReportResolution;
};

export type CompanyCalendarGigDto = {
  id: number;
  resolution: GigResolution;
  status: GigStatus;
  amountOfWorkers: number;
  role: string;
  applications: CompanyCalendarApplication[];
  firstShift?: string;
  lastShift?: string;
  shifts: CompanyCalendarShift[];
  parentGigId?: number;
  type?: GigType;
  workGroupId?: number;
  maxRate?: number;
  minRate?: number;
};

export type CompanyCalendarItemsDtoV2 = {
  groupId: number;
  groupType: GroupType;
  groupName: string;
  gigs: CompanyCalendarGigDtoV2[];
};

export type CompanyCalendarGigDtoV2 = {
  id: number;
  resolution: GigResolution;
  status: GigStatus;
  amountOfWorkers: number;
  role: string;
  applications: CompanyCalendarApplication[];
  firstShift?: string;
  lastShift?: string;
  shifts: CompanyCalendarShift[];
  parentGigId?: number;
  type?: GigType;
  workGroupId?: number;
  maxRate?: number;
  minRate?: number;
};

export type OfferList = {
  workerId: number;
  rate: number;
  usingOfferRate: boolean;
  type: "favorite" | "workgroup";
};

export type NewOffer = {
  gigShiftId: number;
  workersToOfferGigTo: [];
};

export enum FilterType {
  NONE = "none",
  COMPANYENTITY = "CompanyEntity",
  COMPANYUNIT = "CompanyUnit",
  COMPANYWORKGROUP = "WorkGroup",
}

export enum GroupType {
  NONE = "none",
  UNIT = "CompanyUnit",
  WORKGROUP = "WorkGroup",
}

export type Filter = {
  filterId: number | null;
  filterType:
    | FilterType.COMPANYENTITY
    | FilterType.COMPANYUNIT
    | FilterType.COMPANYWORKGROUP
    | FilterType.NONE;
  groupType: GroupType.NONE | GroupType.UNIT | GroupType.WORKGROUP;
};
