import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyGigDto } from "model/Gig";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { fetchWorker, getWorkerImages } from "web-apps/company/utils/utils";
import { CustomIcon } from "components/icon/customIcon.component";
import { OutlineCircle, SmallImage } from "../gig/gigs.styled";
import { Text } from "components/Typography/text.styled";
import {
  ImageContainer,
  ImageWrapper,
  WorkerContainer,
  DropDownRow,
  Container,
  Row,
} from "./gigDetail.styled";
import { Modal } from "@material-ui/core";
import { OfferModal } from "./offerModal.component";
import { CompanyWorkerDto } from "model/Company";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { WorkerDetail } from "../timeReport/workers/workerDetail.component";
import { IconCircularContainer } from "../dashboard/dashboard.styled";
import { Filter, FilterType, GroupType } from "model/Calendar";
import { Api } from "services/api/api.service";
import { ApplicationDto, ApplicationStatus } from "model/Application";

type Props = {
  gigData: CompanyGigDto;
  isModalOpen: boolean;
  shouldDefaultOpen?: boolean;
};

export const GigDetailWorkers: React.FC<Props> = ({
  gigData,
  isModalOpen,
  shouldDefaultOpen = false,
}) => {
  const { t } = useTranslation();
  const [workerImages, setWorkerImages] = useState<string[]>([]);
  const [offeredWorkerImages, setOfferedWorkerImages] = useState<string[]>([]);
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [offerHourlyRate, setOfferHourlyRate] = useState(gigData.maxRate);
  const [offerList, setOfferList] = useState<
    {
      workerId: number;
      rate: number;
      usingOfferRate: boolean;
      type: "favorite" | "workgroup";
    }[]
  >([]);
  const [useOfferRateForAll, setUseOfferRateForAll] = useState(false);
  const [workersOpen, setWorkersOpen] = useState<boolean>(
    shouldDefaultOpen || isModalOpen
  );

  useEffect(() => {
    if (shouldDefaultOpen || isModalOpen) {
      setWorkersOpen(true);
    }
  }, [shouldDefaultOpen, isModalOpen]);

  const [workerIds, setWorkerIds] = useState<(number | null)[]>([]);
  const [offeredWorkerIds, setOfferedWorkerIds] = useState<(number | null)[]>(
    []
  );
  const [worker, setWorker] = useState<CompanyWorkerDto>();
  const [offers, setOffers] = useState<ApplicationDto[]>();
  const [gigApplications, setGigApplications] = useState<ApplicationDto[]>();
  const [companyState] = useCompanyStore();
  const [workerModalOpen, setWorkerModalOpen] = useState(false);
  const lastShiftEndTime = gigData.shifts[gigData.shifts.length - 1]?.endTime;
  const isShiftOngoing = new Date(lastShiftEndTime).getTime() > Date.now();
  const [hoverOffer, setHoverOffer] = useState(false);
  const [filter, setFilter] = useState<Filter[]>([
    {
      filterId: -1,
      groupType: GroupType.NONE,
      filterType: FilterType.NONE,
    },
  ]);

  useEffect(() => {
    if (!gigData.workers) return;

    const workerFirebaseIds = gigData.workers.map(
      (worker) => worker.firebaseId
    );
    const workerIds = gigData.workers.map((worker) => worker.id);
    const workerImageUrls = Array(gigData.amountOfWorkers).fill(null);

    getWorkerImages(workerFirebaseIds).then((data) => {
      workerImageUrls.splice(0, data.length, ...data);
      setWorkerImages(workerImageUrls);
      setWorkerIds(workerIds);
    });
  }, [gigData.workers]);

  useEffect(() => {
    setWorkerModalOpen(true);
  }, [worker]);

  const fetchGigApplications = () => {
    if (companyState.company?.id) {
      Api()
        .company.application.getGigApplications(
          companyState.company.id,
          gigData.id
        )
        .then((res) => {
          setGigApplications(res.data);
        })
        .catch((err) => {
          console.log("Error fetching gig applications", err);
        });
    }
  };

  useEffect(() => {
    fetchGigApplications();
  }, []);

  useEffect(() => {
    let offered: ApplicationDto[] = [];
    if (gigApplications) {
      gigApplications.forEach((application) => {
        if (application.status === ApplicationStatus.OFFERED) {
          offered.push(application);
        }
      });
      setOffers(offered);
    }
  }, [gigApplications]);

  useEffect(() => {
    if (!offers) return;

    const workerFirebaseIds = offers.map((offer) => offer.worker.firebaseId);

    const workerIds = offers.map((offer) => offer.worker.id);
    const workerImageUrls = Array(offers.length).fill(null);

    getWorkerImages(workerFirebaseIds).then((data) => {
      workerImageUrls.splice(0, data.length, ...data);
      setOfferedWorkerImages(workerImageUrls);
      setOfferedWorkerIds(workerIds);
    });
  }, [offers]);

  return (
    <>
      {!isModalOpen ? (
        <DropDownRow
          onClick={() => {
            setWorkersOpen(!workersOpen);
          }}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {`${t("GigsCompany.Worker")} (${gigData.amountOfWorkers} st)`}
          </Text>
          <CustomIcon
            name={workersOpen ? "upward_arrow" : "downward_arrow"}
            size="24px"
            color={Color.BurntSienna}
            style={{ marginTop: 10 }}
          />
        </DropDownRow>
      ) : (
        <Row>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {`${t("GigsCompany.Worker")} (${gigData.amountOfWorkers} st)`}
          </Text>
        </Row>
      )}
      {workersOpen && (
        <>
          <WorkerContainer>
            <ImageContainer>
              <IconCircularContainer
                onClick={() => {
                  if (isShiftOngoing) {
                    setOfferModalOpen(true);
                  }
                }}
                isAccept
                style={{
                  cursor: isShiftOngoing ? "pointer" : "auto",
                  margin: "0 auto",
                  padding: 4,
                  marginTop: 2,
                  marginRight: 4,
                }}
                isMedium
                onMouseEnter={() => setHoverOffer(true)}
                onMouseLeave={() => setHoverOffer(false)}
                isDisabled={!isShiftOngoing}
              >
                <CustomIcon name="addPerson" size="34px" color={Color.White} />
              </IconCircularContainer>
              {hoverOffer && (
                <Text
                  fontSize={FontSize.Small}
                  color={Color.Black}
                  style={{
                    marginLeft: 4,
                    backgroundColor: Color.LightGrey03,
                    padding: 4,
                    borderRadius: 4,
                    marginRight: 4
                  }}
                >
                  {t("GigsCompany.SendOffer")}
                </Text>
              )}
              {workerImages.map((url, index) => {
                const workerId = workerIds[index];

                const handleClick = () => {
                  if (typeof workerId === "number") {
                    fetchWorker(workerId, companyState).then((worker) => {
                      setWorker(worker.data);
                    });
                  }
                };

                return (
                  <ImageWrapper key={index}>
                    {url && url !== "no-image" ? (
                      <SmallImage
                        src={url}
                        style={{
                          cursor: "pointer",
                          border: `2px solid ${Color.LighterPositive}`,
                        }}
                        onClick={handleClick}
                      />
                    ) : url === "no-image" ? (
                      <OutlineCircle accepted>
                        <CustomIcon
                          name="person"
                          size="34px"
                          color={Color.SeaBlue500}
                          style={{
                            cursor: isShiftOngoing ? "pointer" : "auto",
                            margin: "0 auto",
                            padding: 4,
                            marginTop: 2,
                          }}
                          onClick={handleClick}
                        />
                      </OutlineCircle>
                    ) : (
                      <CustomIcon
                        name="person"
                        size="34px"
                        color={Color.SeaBlue500}
                        style={{
                          cursor: isShiftOngoing ? "pointer" : "auto",
                          margin: "0 auto",
                          padding: 4,
                          marginTop: 2,
                        }}
                        onClick={() => {
                          if (isShiftOngoing) {
                            setOfferModalOpen(true);
                          }
                        }}
                      />
                    )}
                  </ImageWrapper>
                );
              })}
              {offeredWorkerImages.map((url, index) => {
                const offeredWorkerId = offeredWorkerIds[index];

                const handleClick = () => {
                  if (typeof offeredWorkerId === "number") {
                    fetchWorker(offeredWorkerId, companyState).then(
                      (worker) => {
                        setWorker(worker.data);
                      }
                    );
                  }
                };
                return (
                  <ImageWrapper key={index}>
                    {url && url !== "no-image" ? (
                      <SmallImage
                        src={url}
                        style={{
                          cursor: "pointer",
                          border: "2px solid orange",
                        }}
                        onClick={handleClick}
                      />
                    ) : (
                      <OutlineCircle>
                        <CustomIcon
                          name="person"
                          size="34px"
                          color={Color.SeaBlue500}
                          style={{
                            cursor: "pointer",
                            margin: "0 auto",
                            padding: 4,
                            marginTop: 2,
                          }}
                          onClick={handleClick}
                        />
                      </OutlineCircle>
                    )}
                  </ImageWrapper>
                );
              })}
            </ImageContainer>
          </WorkerContainer>
        </>
      )}
      <Modal
        open={offerModalOpen}
        onClose={() => {
          setOfferModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <OfferModal
          offerList={offerList}
          setOfferList={setOfferList}
          createGig={false}
          gigData={gigData}
          setOfferModalOpen={setOfferModalOpen}
          offerRate={offerHourlyRate}
          setOfferRate={setOfferHourlyRate}
          useOfferRateForAll={useOfferRateForAll}
          setUseOfferRateForAll={setUseOfferRateForAll}
          filter={filter}
          fromGigView
          fetchGigApplications={() => fetchGigApplications()}
        />
      </Modal>
      {workerModalOpen && worker && (
        <WorkerDetail
          worker={worker}
          setWorkerModalOpen={setWorkerModalOpen}
          workerModalOpen={workerModalOpen}
        />
      )}
    </>
  );
};
