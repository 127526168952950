import React, { useState } from "react";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import { NotFilledGigModalBody } from "./weeklyCalendar.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { CompanyCalendarGigDto, Filter, OfferList } from "model/Calendar";
import { OfferModal } from "../gigDetail/offerModal.component";
import { GigType } from "model/Gig";

type NotFilledGigModalProps = {
  showNotFilledModal: boolean;
  setShowNotFilledModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteShift: (shiftId: number, gigId: string) => void;
  shiftData?: CompanyCalendarGigDto;
  clickedShiftId?: number;
  filter: Filter[];
};

export const NotFilledGigModal: React.FC<NotFilledGigModalProps> = ({
  showNotFilledModal,
  setShowNotFilledModal,
  deleteShift,
  shiftData,
  clickedShiftId,
  filter,
}) => {
  const { t } = useTranslation();
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [offerList, setOfferList] = useState<OfferList[]>([]);
  const [offerHourlyRate, setOfferHourlyRate] = useState(shiftData?.maxRate ? shiftData.maxRate : 0);
  const [useOfferRateForAll, setUseOfferRateForAll] = useState(false);

  return (
    <>
      <Modal
        open={showNotFilledModal}
        onClose={() => {
          setShowNotFilledModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <NotFilledGigModalBody>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.PitchBlack}
            fontSize={FontSize.H3}
          >
            {t("CalendarCompany.NotFilled")}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            color={Color.PitchBlack}
          >
            {t("CalendarCompany.ThisGigHasNotBeenFilled")}
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "40px",
            }}
          >
            {shiftData?.type !== GigType.PUBLIC && (
              <ButtonStyled
                backgroundColor={Color.ModernGreen}
                onClick={() => {
                  setShowNotFilledModal(false);
                  setOfferModalOpen(true);
                }}
              >
                {t("CalendarCompany.Offer")}
              </ButtonStyled>
            )}
            <ButtonStyled
              backgroundColor={Color.LighterDestructive}
              isCancel
              onClick={() => {
                if (shiftData && clickedShiftId) {
                  deleteShift(clickedShiftId, shiftData.id.toString());
                }
                setShowNotFilledModal(false);
              }}
            >
              {t("CompanyTimeReport.DeleteShift")}
            </ButtonStyled>
          </div>
        </NotFilledGigModalBody>
      </Modal>
      <Modal
        open={offerModalOpen}
        onClose={() => {
          setOfferModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <OfferModal
          offerList={offerList}
          setOfferList={setOfferList}
          createGig={false}
          gigData={shiftData}
          setOfferModalOpen={setOfferModalOpen}
          offerRate={offerHourlyRate}
          setOfferRate={setOfferHourlyRate}
          useOfferRateForAll={useOfferRateForAll}
          setUseOfferRateForAll={setUseOfferRateForAll}
          clickedShift={clickedShiftId}
          filter={filter}
          type={shiftData?.type}
          selectedWorkGroup={
            shiftData?.type == GigType.WORKPOOL ? shiftData.workGroupId : null
          }
        />
      </Modal>
    </>
  );
};
