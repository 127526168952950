import { AxiosResponse } from "axios";
import { axiosRequest } from "../api.utils";
import { API_ROOT } from "services/baseURLs.config";
import { CompanyCalendarItemsDtoV2 } from "model/Calendar";

const getCalendarGigsV2 = async (
  companyId: number,
  filterId: number | null,
  filterType: string,
  groupType: string,
  from: number,
  end: number
): Promise<AxiosResponse<CompanyCalendarItemsDtoV2[]>> => {
  return await axiosRequest.auth
    .get<CompanyCalendarItemsDtoV2[]>(
      `${API_ROOT}/companies/${companyId}/Calendar/${from}-${end}/v2?filterId=${filterId}&filterType=${filterType}&groupType=${groupType}`
    )
    .then((res) => res);
};

export const calendar = {
  getCalendarGigsV2,
};
