import styled from "styled-components";
import { Color } from "config/colors";
import { FontSize } from "config/font";
import { CustomLink } from "components/Typography/text.styled";

export const ApplicantGrid = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

  @media (min-width: 768px) {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: minmax(270px, 1fr) 2px 2fr;
    grid-template-rows: 250px auto;
  }
`;
export const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  div {
    margin: 0 0 10px 0;
  }
`;

export const ProfileDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 1/2;
  grid-row: 1/3;
  padding: 20px 0;
  align-self: center;
  text-align: center;
  width: 100%;

  & > *:nth-child(2) {
    margin: 0 0 10px 0;
  }

  p {
    margin: 5px 0;
  }

  @media (min-width: 768px) {
    align-self: start;
  }
`;

export const CustomModalBody = styled.div`
  > * {
    &:first-child {
      width: 350px;
    }
  }
  @media (max-width: 768px) {
    > * {
      &:first-child {
        width: 100%;
      }
    }
  }
  @media (min-width: 768px) {
    align-self: start;
  }
`;

export const AboutDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  grid-column: 3/4;
  grid-row: 1/3;
  padding: 20px 0;
  align-items: center;

  div:nth-child(2) {
    max-height: 420px;
    overflow-y: auto;
  }
`;

export const DividerLine = styled.div`
  background-color: ${Color.BurntSienna};

  width: 30px;
  height: 5px;
  align-self: center;

  @media (min-width: 768px) {
    width: 100%;
    height: 100%;
    grid-column: 2/3;
    grid-row: 1/3;
    background-color: ${Color.SeaBlue200};
  }
`;

export const CompanyImage = styled.img`
  width: 250px;
  height: 190px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(185, 185, 185, 0.3);
`;

export const Cell = styled.div`
  padding: 10px;
  background-color: ${Color.SeaBlue200};
  border-radius: 10px;
  width: 100%;
  text-align: left;

  p {
    margin-bottom: 0;
  }
`;

export const GreenCell = styled(Cell)`
  grid-column: 2/3;
  grid-row: 1/2;
  background: ${Color.Positive};
  text-align: left;
  max-height: 300px;
  margin-bottom: 20px;
`;

export const SkillWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: ${Color.SeaBlue200};
  border-radius: 8px;
  margin: 5px;
  p {
    margin: 0 5px;
  }
`;

export const ProfileDetailsContainer = styled.div`
  padding: 10px 0;
  border-radius: 10px;
  text-align: left;
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
`;

export const SkillsContainer = styled.div`
  padding: 10px 0;
  border-radius: 10px;
  text-align: left;
  width: 100%;
`;

export const SkillsCell = styled(ProfileDetailsContainer)`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  border-radius: 10px;
  text-align: left;
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
`;

export const VideoContainer = styled(ProfileDetailsContainer)`
  max-height: unset;
  padding-bottom: 0;
  margin-bottom: 10px;
`;

export const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  margin-top: 5px;
`;

export const VideoPresentation = styled.video`
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  outline: none;
  border-radius: 10px;
`;

export const RowWrapper = styled.div`
  position: absolute;
  bottom: -50px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 3/4;
  grid-row: 3/4;
  margin-top: 20px;
`;

export const ModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 370px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);
  button {
    font-size: ${FontSize.Small};
  }
  p {
    margin: 0 0 30px 0;
  }
  div {
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    margin: 0 10px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 190px;
  margin-bottom: 30px;

  .icon {
    position: absolute;
    bottom: -10px;
    right: -10px;
  }
`;

export const BackCustomLink = styled(CustomLink)`
  display: flex;
  margin: 0 0 5px 0;

  a {
    cursor: pointer;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 0px;
  }
`;

export const SkillCell = styled.div`
  width: 270px;
  display: flex;
  flex-direction: row;
  padding: 8px;
  margin: 4px;
  justify-content: space-between;
  p {
    margin: 0;
  }
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin: 16px 0 16px 0;
`;

export const ApplicantModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 900px;
  height: 80vh;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  //overflow-y: scroll;
  outline: none;
  border-radius: 8px;

  button {
    margin-bottom: 20px;
  }
`;
