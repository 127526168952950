import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { Color } from "config/colors";
import { FontFamily } from "config/font";
import { Api } from "services/api/api.service";
import { getDateString, getWorkHours } from "utils/utils";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { GigContract as GigContractType } from "model/GigContract";
import { CustomLink, H4, Text } from "components/Typography/text.styled";
import {
  FlexRow,
  LinkWrapper,
  FlexCenter,
  FlexColumn,
} from "./gigContract.styled";

type Props = {
  applicationId: number | null;
  closeContract: () => void;
};

export const GigContract: React.FC<Props> = ({
  applicationId,
  closeContract,
}) => {
  const { t } = useTranslation();

  const [companyState] = useCompanyStore();
  const [contractInfo, setContractInfo] = useState<
    (GigContractType & { displayGigDuration: string }) | null
  >(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (companyState.company && applicationId) {
      Api()
        .company.gig.getGigContract(companyState.company.id, applicationId)
        .then((res) => {
          setContractInfo({
            ...res.data,
            displayGigDuration: getWorkHours(t, ...res.data.gig.shifts),
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [applicationId, companyState.company]);

  return (
    <>
      <LinkWrapper>
        <CustomLink
          color={Color.BurntSienna}
          align="right"
          onClick={() => {
            closeContract();
          }}
        >
          {t("ProfileCompany.Done")}
        </CustomLink>
      </LinkWrapper>
      {loading ? (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      ) : (
        <>
          {contractInfo && (
            <>
              <H4 style={{ margin: "20px 0" }}>{t("General.Employee")}</H4>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("ProfileWorker.FirstName")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.worker.firstName}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("ProfileWorker.LastName")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.worker.lastName}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("ProfileWorker.PersonalNumber")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.worker.ssn}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("ProfileWorker.Address")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.worker.address}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("ProfileWorker.PostalCode")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.worker.postalCode}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("ProfileWorker.City")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.worker.city}
                </Text>
              </FlexRow>
              <H4 style={{ margin: "50px 0 20px" }}>{t("General.Employer")}</H4>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("ProfileCompany.LegalName")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.company.companyName}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("ProfileCompany.RegistrationNumber")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.company.registrationNumber}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("ProfileCompany.Address")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.company.address}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("ProfileCompany.PostalCode")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.company.postalCode}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("ProfileCompany.City")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.company.city}
                </Text>
              </FlexRow>
              <H4 style={{ margin: "50px 0 20px" }}>
                {t("General.GigDetails")}
              </H4>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("GigDetails.Role")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.gig.role}
                </Text>
              </FlexRow>
              <FlexColumn>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("GigDetails.Description")}
                </Text>
                <Text
                  style={{ whiteSpace: "pre-line" }}
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.gig.description}
                </Text>
              </FlexColumn>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("GigDetails.StartDate")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {getDateString(contractInfo.gig.shifts[0].startTime)}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {`${t("GigsWorker.HourlyRate")} (${t("General.Currency")})`}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {`${contractInfo.application.requestedRate} (${t(
                    "GigDetails.HolidayPayIncluded"
                  )})`}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("GigDetails.TotalHours")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.displayGigDuration}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("GigDetails.FormOfEmployment")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {t("GigDetails.IntermittentEmployment")}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("GigDetails.EndDate")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {getDateString(
                    contractInfo.gig.shifts[contractInfo.gig.shifts.length - 1]
                      .startTime
                  )}
                </Text>
              </FlexRow>
              <FlexRow>
                <Text
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.SeaBlue600}
                >
                  {t("GigDetails.ApprovedByBothParties")}
                </Text>
                <Text
                  fontFamily={FontFamily.MontserratBold}
                  color={Color.MidnightBlue}
                >
                  {contractInfo.application.acceptedAt
                    ? getDateString(contractInfo.application.acceptedAt) +
                      ", " +
                      contractInfo.company.city
                    : "-"}
                </Text>
              </FlexRow>
            </>
          )}
        </>
      )}
    </>
  );
};
