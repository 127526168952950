import { useEffect, useState } from "react";
import { Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { Dropdown, ListViewModel } from "components/dropdown/Dropdown";
import { Color } from "config/colors";
import {
  Wrapper,
  DropdownWrapper,
  SaveButton,
  StyledProgress,
} from "./calendarTemplatePicker.styled";
import { useTranslation } from "react-i18next";
import { CalendarTemplateDto } from "model/GigTemplate";
import { adjustShiftsToWeek } from "web-apps/company/utils/utils";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import { CompanyCalendarGigDto } from "model/Calendar";
import {
  GigResolution,
  GigStatus,
} from "model/Gig";
import { CircularProgress} from "@material-ui/core";
import { useSaveManyGigs } from "hooks/useSaveManyGigs";
import { useGetManyGigs } from "hooks/useGetManyGigs";
import moment, { Moment } from "moment";
import { CalendarDeleteTemplate } from "./calendarDeleteTemplate.component";
import { CreateCalendarTemplate } from "./createCalendarTemplate.component";
import { PreviewCalendarModal } from "./previewCalendarModal.component";

type Props = {
  startDate: Date;
  refetchCalendarGigs: () => void;
  currentDates: { date: Moment; weekDay: string }[];
};

export const CalendarTemplatePicker = ({
  startDate,
  refetchCalendarGigs,
  currentDates,
}: Props) => {
  const [calendarState] = useCalendarStore();
  const [templates, setTemplates] = useState<CalendarTemplateDto[]>([]);
  const [selectedTemplate, setSelectedTemplate] =
    useState<CalendarTemplateDto>();
  const [calendarData, setCalendarData] = useState<CompanyCalendarGigDto[]>([]);
  const [isLoadingApi, setIsLoadingApi] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isSaveOpen, setIsSaveOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [gigs, isLoadingCreateGigs, , saveManyGigs] = useSaveManyGigs();
  const [, , , getManyGigs] = useGetManyGigs();
  const { t } = useTranslation();
  const isLoading = isLoadingApi || isLoadingCreateGigs;
  const [templateToDelete, setTemplateToDelete] =
    useState<ListViewModel | null>(null);
  const [passedShifts, setPassedShifts] = useState(false);


  const onTemplateSelected = (vm: ListViewModel) => {
    const { value } = vm;
    const weekTemplate = templates.find((t) => t.id === value);
    if (weekTemplate) {
      const calendarDtos: CompanyCalendarGigDto[] = [];
      let hasPassedShifts = false;

      weekTemplate.gigs.forEach((gigTemplate, id) => {
        let adjustedShifts = adjustShiftsToWeek(gigTemplate, startDate);
        const { amountOfWorkers, role } = gigTemplate;

        let filteredShifts = adjustedShifts.filter(
          (shift) => new Date(shift.startTime) > new Date()
        );
        if (filteredShifts.length < adjustedShifts.length) {
          hasPassedShifts = true;
        }
        if (filteredShifts?.length > 0) {
          calendarDtos.push({
            id,
            resolution: GigResolution.NOT_FILLED,
            status: GigStatus.CREATED,
            amountOfWorkers,
            role,
            applications: [],
            shifts: filteredShifts.map(({ startTime, endTime }, shiftId) => ({
              id: shiftId,
              timeReports: [],
              startTime,
              endTime,
            })),
            type: gigTemplate.type
          });
        }
      });
      setPassedShifts(hasPassedShifts);
      const sorted = [...calendarDtos].sort(
        (e1, e2) =>
          new Date(e1.shifts[0].startTime).getTime() -
          new Date(e2.shifts[0].startTime).getTime()
      );
      setCalendarData(sorted);
      setSelectedTemplate(weekTemplate);
      setIsPreviewOpen(true);
    }
  };

  const onRemoveTemplate = (vm: ListViewModel) => {
    setShowDeleteModal(true);
    setTemplateToDelete(vm);
  };

  useEffect(() => {
    if (!isPreviewOpen) {
      setSelectedTemplate(undefined);
    }
  }, [isPreviewOpen]);

  return (
    <Wrapper>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <DropdownWrapper>
          {templates?.length > 0 ? (
            <Dropdown
              textColor={Color.MidnightBlue}
              smallerFont
              smallerHeight
              placeholder={t("GigsCompany.SelectTemplate")}
              activeValue={selectedTemplate?.id}
              items={templates.map((m: any) => ({
                value: m.id,
                label: m.name,
              }))}
              disabled={isLoading}
              showSpinner={isLoading}
              maxHeight={200}
              onChange={onTemplateSelected}
              onRemove={(onRemoveTemplate)}
            />
          ) : isLoading ? (
            <StyledProgress size={18} color="inherit" />
          ) : (
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Small}
              color={Color.SeaBlue600}
              style={{ margin: 0 }}
            >
              {t("GigsCompany.CalendarTemplateEmpty")}
            </Text>
          )}
        </DropdownWrapper>
        <SaveButton
          backgroundColor={Color.ModernGreen}
          icon={isLoadingApi}
          disabled={!calendarState.currentWeekV2.length || isLoading}
          onClick={() => {
            setTemplateName(
              `${t("CalendarCompany.Week")} ${moment(startDate).week()}`
            );
            setIsSaveOpen(true);
          }}
        >
          {isLoadingApi ? (
            <CircularProgress size={18} color="inherit" />
          ) : (
            t("GigsCompany.CreateCalendarTemplate")
          )}
        </SaveButton>
      </div>
      <PreviewCalendarModal
        startDate={startDate}
        refetchCalendarGigs={refetchCalendarGigs}
        currentDates={currentDates}
        setTemplates={setTemplates}
        calendarData={calendarData}
        setIsLoadingApi={setIsLoadingApi}
        passedShifts={passedShifts}
        selectedTemplate={selectedTemplate}
        isPreviewOpen={isPreviewOpen}
        setIsPreviewOpen={setIsPreviewOpen}
      />
      <CreateCalendarTemplate
        setIsLoadingApi={setIsLoadingApi}
        isSaveOpen={isSaveOpen}
        setIsSaveOpen={setIsSaveOpen}
      />
      <CalendarDeleteTemplate
        setIsLoadingApi={setIsLoadingApi}
        templateToDelete={templateToDelete}
        setSelectedTemplate={setSelectedTemplate}
        setShowDeleteModal={setShowDeleteModal}
        showDeleteModal={showDeleteModal}
      />
    </Wrapper>
  );
};
