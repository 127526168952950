import { CircularProgress } from '@material-ui/core';
import { CustomIcon } from 'components/icon/customIcon.component';
import { Text } from "components/Typography/text.styled";
import { Color } from 'config/colors';
import { FontFamily, FontSize } from 'config/font';
import { CompanyStructureDto, Unit } from 'model/CompanyStructure';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api, handleApiCall } from 'services/api/api.service';
import { useCompanyStore } from 'web-apps/company/stores/companyStore/companyStore';
import { IconCircularContainer } from '../../dashboard/dashboard.styled';
import { CompanyContainer, DividerLine, EntityContainer } from './companyStructure.styled';
import { EditEntityConfig, EditEntityModal } from './companyStructureEditEntity.component';
import EntityComponent from './companyStructureEntity.component';
import UnitComponent from './companyStructureUnit.component';
import { EditUnit } from './editUnit/companyStructureEditUnit.component';
import { LoadingContainer, Row } from './editUnit/companyStructureEditUnit.styled';
import { BannerComponent } from 'components/banners/banner.component';

interface EditUnitConfig {
  companyId: number,
  entityId: number,
  unit: Unit | null,
}

const CompanyStructure: React.FC = () => {
  const { t } = useTranslation()

  const initialExpandedEntitiesAmount = 5
  const initialExpandedUnitsAmount = 5

  const [expandedEntities, setExpandedEntities] = useState<number[]>([]);
  const [expandedUnits, setExpandedUnits] = useState<number[]>([]);

  const [company, setCompany] = useState<CompanyStructureDto | null>(null)

  const [companyState] = useCompanyStore();

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [showEditEntity, setShowEditEntity] = useState<boolean>(false)
  const [entityToEdit, setEntityToEdit] = useState<EditEntityConfig | null>(null)

  const [showEditUnit, setShowEditUnit] = useState<boolean>(false)
  const [editUnitConfig, setEditUnitConfig] = useState<EditUnitConfig | null>(null)

  const [triggerRefresh, setTriggerFetchRefresh] = useState(0);

  const incrementTriggerFetch = () => {
    setTriggerFetchRefresh((prevValue) => prevValue + 1);
  };

  const fetchCompanyStructureData = () => {
    if (companyState.company && companyState.company.id) {
      setIsLoading(true)

      handleApiCall({
        apiCall: () => Api().company.fetchCompanyStructure(companyState.company?.id!!),
        onResult: (res) => { setCompany(res.data) },
        onCompleted: () => { setIsLoading(false) },
      })
    }
  }

  useEffect(() => {
    fetchCompanyStructureData();
  }, [companyState.company?.id, triggerRefresh]);

  useEffect(() => {
    if (company) {
      const initialExpandedEntities = company.entities.slice(0, initialExpandedEntitiesAmount).map(entity => entity.id);
      setExpandedEntities(initialExpandedEntities);

      const initialExpandedUnits = company.entities.flatMap(entity => entity.units.slice(0, initialExpandedUnitsAmount).map(unit => unit.id));
      setExpandedUnits(initialExpandedUnits);
    }
  }, [company]);

  const toggleEntityExpand = (entityId: number) => {
    setExpandedEntities((prev) =>
      prev.includes(entityId) ? prev.filter((id) => id !== entityId) : [...prev, entityId]
    );
  };

  const toggleUnitExpand = (unitId: number) => {
    setExpandedUnits((prev) =>
      prev.includes(unitId) ? prev.filter((id) => id !== unitId) : [...prev, unitId]
    );
  };

  return (
    <>
      {!companyState.company || companyState.isLoading ? (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      ) : (company && !companyState.isLoading && companyState.company?.verified ? (
        <CompanyContainer>
          <Row>
            <h1>{company.name}</h1>
            <LoadingContainer>
              {isLoading && (
                <CircularProgress />
              )}
            </LoadingContainer>
          </Row>

          <Text fontSize={FontSize.Large}>{t("CompanyStructure.HereYouCanSeeTheOverviewStructure")}</Text>

          {company.entities.map((entity) => (
            <EntityContainer key={entity.id}>
              <EntityComponent
                key={entity.id}
                entity={entity}
                expandedEntities={expandedEntities}
                toggleEntityExpand={toggleEntityExpand}
                setEntityToEdit={setEntityToEdit}
                setShowEditEntity={setShowEditEntity}
              />

              {expandedEntities.includes(entity.id) && (
                <>
                  {entity.units.map((unit) => (
                    <UnitComponent
                      key={unit.id}
                      unit={unit}
                      company={company}
                      entity={entity}
                      expandedUnits={expandedUnits}
                      toggleUnitExpand={toggleUnitExpand}
                      setEditUnitConfig={setEditUnitConfig}
                      setShowEditUnit={setShowEditUnit}
                    />
                  ))}

                  <IconCircularContainer
                    style={{ marginLeft: 20, marginTop: 10, marginBottom: 10 }}
                    isAccept
                    onClick={() => {
                      setEditUnitConfig({
                        companyId: company.id,
                        entityId: entity.id,
                        unit: null,
                      })

                      setShowEditUnit(true)
                    }}
                  >
                    <CustomIcon
                      style={{ zIndex: 1 }}
                      name="plus_old"
                      size="24px"
                      padding="2px"
                      color={Color.White}
                    />
                  </IconCircularContainer>
                </>
              )}
            </EntityContainer>
          ))}

          <DividerLine style={{ marginLeft: 20 }} />

          {/*<IconCircularContainer
            style={{ marginLeft: 20, marginTop: 20 }}
            isAccept
            onClick={() => { setShowEditEntity(true) }}
          >
            <CustomIcon
              style={{ zIndex: 1000 }}
              name="plus_old"
              size="24px"
              padding="2px"
              color={Color.White}
            />
          </IconCircularContainer>*/}

          {showEditEntity && (
            <EditEntityModal
              onStructureRefresh={incrementTriggerFetch}
              onClose={() => {
                setShowEditEntity(false)
                setEntityToEdit(null)
              }}
              companyId={company.id}
              entity={entityToEdit} />
          )}

          {showEditUnit && editUnitConfig && (
            <EditUnit
              companyId={editUnitConfig.companyId}
              entityId={editUnitConfig.entityId}
              unitInit={editUnitConfig.unit}
              onClose={(structureHasUpdated) => {
                setShowEditUnit(false)
                setEditUnitConfig(null)

                if (structureHasUpdated) {
                  incrementTriggerFetch()
                }
              }}
            />
          )}
        </CompanyContainer>
      ) : (!companyState.company.verified &&
        <BannerComponent
          icon="clock"
          backgroundColor={Color.ChampagnePink}
          fill={Color.Destructive}
        >
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.Destructive}
          >
            {t("GigsCompany.UnverifiedAccount")}
          </Text>
          <Text>{t("GigsCompany.UnverifiedAccountDetails")}</Text>
        </BannerComponent>
      ))
      }
    </>
  )
}

export default CompanyStructure;
