import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CompanyGigDto } from "model/Gig";
import { getWorkHours, getDateString } from "utils/utils";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import {
  Row,
  DetailWrapper,
  TitleAndDetailsContainer,
} from "./activeGig.styled";
import { truncateString } from "./activeGigUtils";
import { GigDetailsModal } from "../calendar/gigDetailsModal.component";

type Props = {
  gigData: CompanyGigDto;
};

export const ActiveGig: React.FC<Props> = ({ gigData }) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [gigModalOpen, setGigModalOpen] = useState(false);

  return (
    <>
      <Row>
        <CustomIcon
          name="pin"
          size="48px"
          color={Color.BurntSienna}
          padding="10px"
        />
        <TitleAndDetailsContainer
          onClick={() => {
            setGigModalOpen(true);
          }}
          style={{ cursor: "pointer" }}
        >
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("GigsCompany.GigAt", {
              position: truncateString(
                t(gigData.role || "", { ns: "roles" }),
                24
              ),
              company: companyState.company?.companyName,
            })}
          </Text>
          <DetailWrapper>
            <Text fontSize={FontSize.Large}>
              {gigData.shifts[0] && getDateString(gigData.shifts[0].startTime)}
            </Text>
            <Text
              color={Color.White}
              backgroundColor={Color.SeaBlue600}
              padding
            >
              {gigData.role &&
                truncateString(t(gigData.role, { ns: "roles" }), 32)}
            </Text>
            <Text
              color={Color.White}
              backgroundColor={Color.SeaBlue600}
              padding
            >
              {getWorkHours(t, ...gigData.shifts)}
            </Text>
          </DetailWrapper>
        </TitleAndDetailsContainer>
      </Row>
      {gigModalOpen && (
        <GigDetailsModal
          gigId={gigData.id}
          setModalOpen={setGigModalOpen}
          modalOpen
        />
      )}
    </>
  );
};
