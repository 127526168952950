import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyGigDto } from "model/Gig";
import { Color } from "config/colors";
import { FontFamily } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { DescriptionContainer, DropDownRow, Row } from "./gigDetail.styled";
import { FontSize } from "config/font";

type Props = {
  gigData: CompanyGigDto;
  isModalOpen: boolean;
};

export const GigDetailDescription: React.FC<Props> = ({
  gigData,
  isModalOpen,
}) => {
  const { t } = useTranslation();
  const [descriptionOpen, setDescriptionOpen] = useState(isModalOpen);

  return (
    <>
      {!isModalOpen ? (
        <DropDownRow
          onClick={() => {
            setDescriptionOpen(!descriptionOpen);
          }}
        >
          <Text fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.Large}>
            {t("GigsCompany.Description")}
          </Text>
          <CustomIcon
            name={descriptionOpen ? "upward_arrow" : "downward_arrow"}
            size="24px"
            color={Color.BurntSienna}
            style={{ marginTop: 10 }}
          />
        </DropDownRow>
      ) : (
        <Row>
          <Text fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.Large}>
            {t("GigsCompany.Description")}
          </Text>
        </Row>
      )}
      {descriptionOpen && (
        <DescriptionContainer>
          {gigData.description ? (
            <Text style={{ whiteSpace: "pre-line" }}>
              {gigData.description}
            </Text>
          ) : null}
        </DescriptionContainer>
      )}
    </>
  );
};
