import { CalendarState } from "./calendarStore.reducer";
import { CalendarDispatch } from "./calendarStore";
import {
  CompanyCalendarGigDto,
  CompanyCalendarItemsDtoV2,
  Filter,
} from "model/Calendar";
import { ApplicationDto } from "model/Application";
import { TimeReportDto } from "model/TimeReport";
import { addLeadingZero } from "utils/utils";
import moment from "moment";
import { CompanyState } from "../companyStore/companyStore.reducer";
import { Api } from "services/api/api.service";

const removeGigFromCalendarContext =
  (dispatch: CalendarDispatch, state: CalendarState) => (gigId: number) => {
    let newContext: CompanyCalendarItemsDtoV2[] = JSON.parse(
      JSON.stringify(state.currentWeekV2)
    );

    newContext = newContext.map((item) => ({
      ...item,
      gigs: item.gigs.filter((gig) => gig.id !== gigId),
    }));

    dispatch({
      type: "UPDATE_WEEKLY_CALENDAR_V2",
      payload: newContext,
    });
  };

const updateApplicationsCalendarContext =
  (dispatch: CalendarDispatch, state: CalendarState) =>
  (applications: ApplicationDto[]) => {
    let newContext: CompanyCalendarItemsDtoV2[] = JSON.parse(
      JSON.stringify(state.currentWeekV2)
    );
    let newCalendarApplications = applications.map((app) => ({
      id: app.id,
      status: app.status,
      resolution: app.resolution,
      worker: {
        firstName: app.worker.firstName,
        lastName: app.worker.lastName,
        id: app.worker.id,
      },
    }));
    const gigId = applications[0].gigId;

    newContext.map((item) => {
      const gigIndex = item.gigs.findIndex((gig) => gig.id === gigId);
      item.gigs[gigIndex].applications = [
        ...item.gigs[gigIndex].applications,
        ...newCalendarApplications,
      ];
    });

    dispatch({
      type: "UPDATE_WEEKLY_CALENDAR_V2",
      payload: newContext,
    });
  };
const updateTimeReportCalendarContext =
  (dispatch: CalendarDispatch, state: CalendarState) =>
  (newTimeReport: TimeReportDto) => {
    const newCalendarWeek: CompanyCalendarItemsDtoV2[] = JSON.parse(
      JSON.stringify(state.currentWeekV2)
    );

    const groupIndex = newCalendarWeek.findIndex((group) =>
      group.gigs.some((gig) =>
        gig.shifts.some((shift) =>
          shift.timeReports.some(
            (timeReport) => timeReport.id === newTimeReport.id
          )
        )
      )
    );

    if (groupIndex !== -1) {
      const group = newCalendarWeek[groupIndex];
      const gigIndex = group.gigs.findIndex((gig) =>
        gig.shifts.some((shift) =>
          shift.timeReports.some(
            (timeReport) => timeReport.id === newTimeReport.id
          )
        )
      );

      if (gigIndex !== -1) {
        const gig = group.gigs[gigIndex];
        const shiftIndex = gig.shifts.findIndex((shift) =>
          shift.timeReports.some(
            (timeReport) => timeReport.id === newTimeReport.id
          )
        );

        if (shiftIndex !== -1) {
          const shift = gig.shifts[shiftIndex];

          const updatedTimeReports = shift.timeReports.map((timeReport) =>
            timeReport.id === newTimeReport.id
              ? {
                  ...timeReport,
                  workerId: newTimeReport.workerId,
                  startTime: newTimeReport.startTime,
                  endTime: newTimeReport.endTime,
                  status: newTimeReport.status,
                  resolution: newTimeReport.resolution,
                }
              : timeReport
          );

          group.gigs[gigIndex].shifts[shiftIndex] = {
            ...shift,
            timeReports: updatedTimeReports,
          };

          dispatch({
            type: "UPDATE_WEEKLY_CALENDAR_V2",
            payload: newCalendarWeek,
          });
        }
      }
    }
  };

const createDateNumbersCalendar = (
  currentDates: {
    date: moment.Moment;
    weekDay: string;
  }[]
) => {
  const firstDate = currentDates[0].date;
  const lastDate = currentDates[6].date;

  const start = `${firstDate.year()}${addLeadingZero(
    firstDate.month() + 1
  )}${addLeadingZero(firstDate.date())}`;
  const end = `${lastDate.year()}${addLeadingZero(
    lastDate.month() + 1
  )}${addLeadingZero(lastDate.date())}`;
  return { start: parseInt(start), end: parseInt(end) };
};

const updateWeeklyCalendarContext =
  (dispatch: CalendarDispatch, state: CalendarState) =>
  (
    t: any,
    companyState: CompanyState,
    type: "UPDATE_WEEKLY_CALENDAR_V2" | "UPDATE_OVERVIEW_WEEK_CALENDAR_V2",
    filter: Filter[]
  ) => {
    const today = moment();

    const currentDate =
      type === "UPDATE_WEEKLY_CALENDAR_V2" &&
      localStorage.getItem("CalendarDate")
        ? moment(localStorage.getItem("CalendarDate"))
        : today;

    const todayWeekday = currentDate.day();

    const firstDayOfWeek = moment(
      moment(currentDate).subtract(todayWeekday - 1, "days")
    );

    const weekdayList = [
      t("WeekDays.MONDAYSHORT"),
      t("WeekDays.TUESDAYSHORT"),
      t("WeekDays.WEDNESDAYSHORT"),
      t("WeekDays.THURSDAYSHORT"),
      t("WeekDays.FRIDAYSHORT"),
      t("WeekDays.SATURDAYSHORT"),
      t("WeekDays.SUNDAYSHORT"),
    ];
    let dates: {
      date: moment.Moment;
      weekDay: string;
    }[] = [];

    for (let x = 0; x < 7; x++) {
      dates.push({
        date: moment(moment(firstDayOfWeek).add(x, "days")),
        weekDay: weekdayList[x],
      });
    }

    if (companyState.company?.id && dates.length > 0) {
      dispatch({
        type: "SET_IS_LOADING",
        payload: true,
      });

      Api()
        .company.calendar.getCalendarGigsV2(
          companyState.company.id,
          filter[0].filterId,
          filter[0].filterType,
          filter[0].groupType,
          createDateNumbersCalendar(dates).start,
          createDateNumbersCalendar(dates).end
        )
        .then((res) => {
          dispatch({
            type: "SET_IS_LOADING",
            payload: false,
          });
          dispatch({
            type: type,
            payload: (res.data as any).items,
          });
        })
        .catch((err) => {
          dispatch({
            type: "SET_IS_LOADING",
            payload: false,
          });
          console.log("ERROR FETCHING CALENDAR GIGS", err);
        });
    }
  };

const removeGigFromCalendarContextOverview =
  (dispatch: CalendarDispatch, state: CalendarState) => (gigId: number) => {
    let newContext: CompanyCalendarGigDto[] = JSON.parse(
      JSON.stringify(state.overviewWeek)
    );
    dispatch({
      type: "UPDATE_OVERVIEW_WEEK_CALENDAR",
      payload: newContext.filter((gig) => gig.id !== gigId),
    });
  };
export const calendarActions = (
  dispatch: CalendarDispatch,
  state: CalendarState
) => ({
  removeGigFromCalendarContext: removeGigFromCalendarContext(dispatch, state),
  updateApplicationsCalendarContext: updateApplicationsCalendarContext(
    dispatch,
    state
  ),
  updateTimeReportCalendarContext: updateTimeReportCalendarContext(
    dispatch,
    state
  ),
  updateWeeklyCalendarContext: updateWeeklyCalendarContext(dispatch, state),

  removeGigFromCalendarContextOverview: removeGigFromCalendarContextOverview(
    dispatch,
    state
  ),
});
