import React, { useState } from "react";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { LinkWrapper, ModalBody } from "./workgroup.styled";
import { TextInput } from "../gig/gigs.styled";
import { WorkGroupRate } from "./workgroupRate.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CustomIcon } from "components/icon/customIcon.component";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";
import { useAlertStore } from "stores/alertStore/alertStore";

type Props = {
  setShowCreateWorkGroupModal: React.Dispatch<React.SetStateAction<boolean>>;
  showCreateWorkGroupModal: boolean;
};

export const WorkGroupCreateWorkGroupModal: React.FC<Props> = ({
  setShowCreateWorkGroupModal,
  showCreateWorkGroupModal,
}) => {
  const { t } = useTranslation();
  const [hourlyRate, setHourlyRate] = useState<string>("0");
  const [monthlyRate, setMonthlyRate] = useState<string>("0");
  const [name, setName] = useState("");
  const [companyState, ,] = useCompanyStore();
  const [, workgroupActions] = useWorkgroupStore();
  const [, , alertDispatch] = useAlertStore();

  const handleCreateWorkGroup = () => {
    if (companyState.company && name && hourlyRate && monthlyRate) {
      workgroupActions
        .createWorkGroup(
          companyState.company.id,
          name,
          false,
          parseInt(hourlyRate),
          parseInt(monthlyRate)
        )
        .then(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark",
              title: t("WorkGroup.Created"),
              message: t("WorkGroup.WorkGroupCreated"),
            },
          });
          setShowCreateWorkGroupModal(false);
        })
        .catch(() => {
          console.log("error when creating workgroup");
        });
    }
  };

  return (
    <>
      <Modal
        open={showCreateWorkGroupModal}
        onClose={() => {
          setShowCreateWorkGroupModal(false);
        }}
      >
        <ModalBody>
          <LinkWrapper>
            <CustomIcon
              color={Color.LighterDestructive}
              onClick={() => {
                setShowCreateWorkGroupModal(false);
              }}
              name={"cross"}
            >
              {t("General.Close")}
            </CustomIcon>
          </LinkWrapper>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.PitchBlack}
            fontSize={FontSize.H3}
            style={{ marginBottom: 10 }}
          >
            {t("WorkGroup.CreateWorkgroup")}
          </Text>
          <TextInput
            style={{ marginTop: 10 }}
            value={name}
            onChange={(e: any) => {
              setName(e.currentTarget.value);
            }}
            maxLength={50}
            placeholder={t("WorkGroup.Name")}
          />
          <WorkGroupRate
            monthlyRate={monthlyRate}
            setMonthlyRate={setMonthlyRate}
            hourlyRate={hourlyRate}
            setHourlyRate={setHourlyRate}
          />
          <ButtonStyled
            style={{ marginTop: 40 }}
            disabled={!name}
            backgroundColor={Color.ModernGreen}
            onClick={() => {
              handleCreateWorkGroup();
            }}
          >
            {t("WorkGroup.Create")}
          </ButtonStyled>
        </ModalBody>
      </Modal>
    </>
  );
};
