import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import {
  ApplicationDto,
  ApplicationResolution,
  ApplicationStatus,
} from "model/Application";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { GigApplicantsListItem } from "./gigApplicantsListItem.component";
import { Text } from "components/Typography/text.styled";
import {
  GigApplicantsListStyled,
  NoApplicantsContainer,
  ModalBody,
  ButtonContainer,
  DividerLine,
  ContractModalBody,
} from "./gigApplicants.styled";
import { TabsStyled, TabStyled } from "../tab/tab.styled";
import { useHistory } from "react-router";
import Modal from "@material-ui/core/Modal";
import { GigContract } from "./gigContract.component";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { LabelStyled, TextArea } from "components/form/inputs/inputs.styled";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";

type Props = {
  applicants: ApplicationDto[];
  gigId?: string;
  setApplicants: React.Dispatch<React.SetStateAction<ApplicationDto[]>>;
};

type ApplicationsByStatusType = {
  [key: string]: ApplicationDto[];
};

enum Response {
  OFFER,
  REJECT,
}

export const GigApplicantsList: React.FC<Props> = ({
  applicants,
  gigId,
  setApplicants,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const status = new URLSearchParams(window.location.search).get("status");

  const [, , alertDispatch] = useAlertStore();
  const [companyState] = useCompanyStore();
  const [, applicationActions] = useApplicationStore();
  const [calendarState, calendarActions] = useCalendarStore();
  const [declineReason, setDeclineReason] = useState("");

  const [applicationsByStatus, setApplicationsByStatus] =
    useState<ApplicationsByStatusType>({
      Pending: [],
      Accepted: [],
      Closed: [],
    });
  const [checkedApplications, setCheckedApplications] = useState<
    ApplicationDto[]
  >([]);
  const [activeApplication, setActiveApplication] = useState<number | null>(
    null
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [respondApplication, setRespondApplication] = useState<Response>();

  const updateApplicationStatus = (
    status: ApplicationStatus.OFFERED | ApplicationStatus.CLOSED
  ) => {
    let updatedApplications = [...applicants];
    checkedApplications.forEach((application) => {
      if (companyState.company) {
        const resolution =
          status === ApplicationStatus.CLOSED
            ? ApplicationResolution.REJECTED
            : ApplicationResolution.UNDEFINED;
        const data = { ...application, status, resolution, declineReason };
        updatedApplications.filter((app) => app.id !== application.id);
        updatedApplications.push(data);
        applicationActions
          .updateApplicationStatus(
            companyState.company.id,
            application.id,
            data
          )
          .then(() => {
            if (status === ApplicationStatus.OFFERED) {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
                  title: t("ApplicationsCompany.Offered"),
                  message: t("ApplicationsCompany.ApplicantHasBeenOfferedGig"),
                },
              });
            } else {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
                  title: t("ApplicationsCompany.Rejected"),
                  message: t("ApplicationsCompany.ApplicationHasBeenRejected"),
                },
              });
            }
            if (calendarState.currentWeekV2.length > 0) {
              calendarActions.updateApplicationsCalendarContext([data]);
            }
            if (applicants && setApplicants) {
              setApplicants(
                applicants.map((app) => {
                  if (app.id === application.id) {
                    return data;
                  } else {
                    return app;
                  }
                })
              );
            }
          })
          .catch(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert",
                title: t("Alerts.OhNo"),
                message: t("ApplicationsCompany.ApplicationCouldNotBeUpdated"),
              },
            });
          });
      }
    });
  };

  const handleShowContract = (application: ApplicationDto) => {
    setActiveApplication(application.id);
  };

  const handleAcceptApplicants = () => {
    setRespondApplication(Response.OFFER);
    setModalOpen(true);
  };

  const handleRejectApplicants = () => {
    setRespondApplication(Response.REJECT);
    setModalOpen(true);
  };

  const handleWithdraw = (applicationId: number) => {
    const withdrawnApplication = applicants.find(app => app.id === applicationId);
    
    if (withdrawnApplication) {
      const updatedApplicants = applicants.filter(app => app.id !== applicationId);

      withdrawnApplication.status = ApplicationStatus.CLOSED;
      withdrawnApplication.resolution = ApplicationResolution.WITHDRAWN;

      setApplicants([...updatedApplicants, withdrawnApplication]);
    }
  };

  const renderModalBody = () => {
    if (respondApplication === Response.OFFER) {
      return (
        <>
          <Text
            align="center"
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t("GigsCompany.OfferGigTo", {
              user: checkedApplications.length,
            })}
          </Text>
          <Text fontSize={FontSize.Large} color={Color.SeaBlue600}>
            {t("GigsCompany.OnceSentOutYouWillNotBeAbleToWithdraw")}
          </Text>
          <ButtonContainer>
            <ButtonStyled
              backgroundColor={Color.Disabled}
              color={Color.SeaBlue500}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              {t("GigsCompany.Discard")}
            </ButtonStyled>
            <ButtonStyled
              onClick={() => {
                updateApplicationStatus(ApplicationStatus.OFFERED);
              }}
              backgroundColor={Color.BurntSienna}
            >
              {t("GigsCompany.SendOffer")}
            </ButtonStyled>
          </ButtonContainer>
        </>
      );
    } else if (respondApplication === Response.REJECT) {
      return (
        <>
          <Text
            align="center"
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {checkedApplications.length > 1
              ? t("GigsCompany.YouAreAboutToRejectApplications", {
                  number: checkedApplications.length,
                })
              : t("GigsCompany.YouAreAboutToReject1Application")}
          </Text>
          <Text fontSize={FontSize.Large} color={Color.SeaBlue600}>
            {t("GigsCompany.ThisCannotBeUndone")}
          </Text>
          <LabelStyled>{t("GigsCompany.NoteToApplicant")}</LabelStyled>
          <TextArea
            placeholder={t("GigsCompany.SendANote")}
            rows={5}
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
          />
          <ButtonContainer>
            <ButtonStyled
              backgroundColor={Color.Disabled}
              color={Color.SeaBlue500}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              {t("GigsCompany.Discard")}
            </ButtonStyled>
            <ButtonStyled
              onClick={() => {
                updateApplicationStatus(ApplicationStatus.CLOSED);
              }}
              backgroundColor={Color.Destructive}
            >
              {t("GigsCompany.Reject")}
            </ButtonStyled>
          </ButtonContainer>
        </>
      );
    }
  };

  useEffect(() => {
    const pendingApplications: ApplicationDto[] = [];
    const acceptedApplications: ApplicationDto[] = [];
    const closedApplications: ApplicationDto[] = [];
    for (const applicant of applicants) {
      if (
        applicant.status === ApplicationStatus.PENDING ||
        applicant.status === ApplicationStatus.OFFERED
      ) {
        pendingApplications.push(applicant);
      } else if (
        applicant.status === ApplicationStatus.CLOSED &&
        applicant.resolution === ApplicationResolution.ACCEPTED
      ) {
        acceptedApplications.push(applicant);
      } else if (
        applicant.status === ApplicationStatus.CLOSED &&
        (applicant.resolution === ApplicationResolution.WITHDRAWN ||
          applicant.resolution === ApplicationResolution.REJECTED ||
          applicant.resolution === ApplicationResolution.UNDEFINED ||
          applicant.resolution === ApplicationResolution.DECLINED)
      ) {
        closedApplications.push(applicant);
      }
    }

    setApplicationsByStatus({
      Pending: pendingApplications,
      Accepted: acceptedApplications,
      Closed: closedApplications,
    });
  }, [applicants]);

  

  return (
    <>
      <TabsStyled>
        <TabStyled
          active={status === "Accepted"}
          onClick={() => {
            if (history.location.pathname.includes("calendar")) {
              history.push(`./calendar?status=Accepted`);
            } else {
              history.push(`./${gigId}?status=Accepted`);
            }
          }}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("ApplicationsCompany.HiredPlural")} (
            {applicationsByStatus.Accepted.length})
          </Text>
        </TabStyled>
        <TabStyled
          active={status === "Pending"}
          onClick={() => {
            if (history.location.pathname.includes("calendar")) {
              history.push(`./calendar?status=Pending`);
            } else {
              history.push(`./${gigId}?status=Pending`);
            }
          }}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Large}
          >
            {t("ApplicationsCompany.Applicants")} (
            {applicationsByStatus.Pending.length})
          </Text>
        </TabStyled>
      </TabsStyled>
      {applicationsByStatus[status || "Pending" || "Offered"]?.length !== 0 ? (
        <>
          <GigApplicantsListStyled>
            {applicationsByStatus[status || "Pending" || "Offered"].map(
              (applicant) => {
                return (
                  <GigApplicantsListItem
                    key={applicant.id}
                    applicantData={applicant}
                    checkedApplications={checkedApplications}
                    setCheckedApplications={setCheckedApplications}
                    handleShowContract={handleShowContract}
                    setApplications={setApplicants}
                    applications={applicants}
                    onWithdraw={handleWithdraw}
                  />
                );
              }
            )}
          </GigApplicantsListStyled>
          {status === "Pending" && (
            <ButtonContainer>
              <ButtonStyled
                disabled={checkedApplications?.length === 0}
                backgroundColor={Color.Destructive}
                onClick={() => {
                  handleRejectApplicants();
                }}
              >
                {t("GigsCompany.Reject")}
              </ButtonStyled>
              <ButtonStyled
                disabled={checkedApplications?.length === 0}
                backgroundColor={Color.BurntSienna}
                onClick={() => {
                  handleAcceptApplicants();
                }}
              >
                {t("GigsCompany.SendOffer")}
              </ButtonStyled>
            </ButtonContainer>
          )}
        </>
      ) : (
        <NoApplicantsContainer>
          <Text color={Color.SeaBlue600}>
            {status === "Accepted"
              ? t("ApplicationsCompany.NoOneHasBeenHiredForThisGig")
              : t(
                  "ApplicationsCompany.YouCurrentlyDontHaveAnyApplicationsForThisGig"
                )}
          </Text>
        </NoApplicantsContainer>
      )}

      {/* REJECTED APPLICATIONS */}
      {status === "Pending" && (
        <div style={{ marginTop: "40px" }}>
          <LabelStyled>{t("GigsCompany.ClosedApplications")}</LabelStyled>
          <DividerLine />
          {applicationsByStatus.Closed?.length !== 0 ? (
            <GigApplicantsListStyled style={{ opacity: 0.7 }}>
              {applicationsByStatus.Closed.map((applicant) => {
                return (
                  <GigApplicantsListItem
                    key={applicant.id}
                    applicantData={applicant}
                    setApplications={setApplicants}
                    applications={applicants}
                    onWithdraw={handleWithdraw}
                  />
                );
              })}
            </GigApplicantsListStyled>
          ) : (
            <>
              <NoApplicantsContainer>
                <Text color={Color.SeaBlue600}>
                  {t(
                    "ApplicationsCompany.YouCurrentlyDontHaveAnyApplicationsForThisGig"
                  )}
                </Text>
              </NoApplicantsContainer>
            </>
          )}
        </div>
      )}

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody>{renderModalBody()}</ModalBody>
      </Modal>

      <Modal
        open={activeApplication !== null}
        onClose={() => {
          setActiveApplication(null);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ContractModalBody>
          <GigContract
            applicationId={activeApplication}
            closeContract={() => setActiveApplication(null)}
          />
        </ContractModalBody>
      </Modal>
    </>
  );
};
