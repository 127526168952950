import React, { useState, useEffect } from "react";
import { Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getWorkerImg } from "services/firebase/firebase.service";
import { CompanyFavoriteDto } from "../../../../../model/Favorite";
import { Color } from "../../../../../config/colors";
import { FontSize } from "config/font";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useCompanyStore } from "../../../stores/companyStore/companyStore";
import { CustomIcon } from "components/icon/customIcon.component";
import {
  ModalBody,
  ModalButtonContainer,
  SmallImage,
} from "../../gig/gigs.styled";
import { ApplicantImageWrapper } from "../../gigApplicants/gigApplicants.styled";
import { ButtonStyled } from "../../../../../components/buttons/buttons.styled";
import { H4, Text } from "../../../../../components/Typography/text.styled";
import { FavoriteWorkerWrapper } from "./favoriteList.styled";
import { FavoriteSettings } from "./favoritesSettings.component";

type Props = {
  favorite: CompanyFavoriteDto;
};

export const FavoriteListItem: React.FC<Props> = ({ favorite }) => {
  const [, , alertDispatch] = useAlertStore();
  const [companyState, companyActions] = useCompanyStore();
  const [modalOpen, setModalOpen] = useState(false);
  const [workerImage, setWorkerImage] = useState("");
  const [settingsModal, setSettingsModal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    getWorkerImg(favorite.workerFirebaseId).then((res) => {
      setWorkerImage(res);
    });
  }, [favorite]);

  const removeFromFavorites = () => {
    if (favorite && companyState.company) {
      companyActions
        .removeCompanyFavorite(companyState.company?.id, favorite.id)
        .then((res) => {
          setModalOpen(false);
        })
        .catch(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.OhNo"),
              message: t("General.ErrorOccurred"),
            },
          });
        });
    }
  };

  return (
    <>
      <FavoriteWorkerWrapper>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ApplicantImageWrapper>
            {workerImage ? (
              <SmallImage src={workerImage} />
            ) : (
              <CustomIcon
                name="person"
                size="100%"
                color={Color.SeaBlue600}
                padding="10px"
              />
            )}
          </ApplicantImageWrapper>
          <Link to={`favorites/${favorite.id}`}>
            <H4>{`${favorite.firstName} ${favorite.lastName}`}</H4>
          </Link>
          {favorite.hourlyRate && (
            <Link to={`favorites/${favorite.id}`}>
              <H4>{`${favorite.hourlyRate} ${t(
                "FindWorker.HourlyRateWithCurrency"
              )}`}</H4>
            </Link>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <CustomIcon
            name="favorites-active"
            size="48px"
            color={Color.BurntSienna}
            padding="10px"
            onClick={() => setModalOpen(true)}
          />
        </div>
      </FavoriteWorkerWrapper>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody>
          <Text align="center" fontSize={FontSize.H4} color={Color.BurntSienna}>
            {t("CompanyFavorite.AreYouSureRemoveFavorite")}
          </Text>
          <ModalButtonContainer>
            <ButtonStyled onClick={removeFromFavorites}>
              {t("CompanyFavorite.Remove")}
            </ButtonStyled>
            <ButtonStyled
              backgroundColor={Color.Disabled}
              color={Color.SeaBlue500}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              {t("General.Abort")}
            </ButtonStyled>
          </ModalButtonContainer>
        </ModalBody>
      </Modal>
    </>
  );
};
