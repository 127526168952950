import React, { useEffect, useState } from "react";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { LinkWrapper, ModalBody } from "./workgroup.styled";
import { WorkGroupRate } from "./workgroupRate.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CustomIcon } from "components/icon/customIcon.component";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import { TextInput } from "../gig/gigs.styled";

type Props = {
  setShowEditWorkGroupModal: React.Dispatch<React.SetStateAction<boolean>>;
  showEditWorkGroupModal: boolean;
  workGroup: any;
};

export const EditWorkGroupModal: React.FC<Props> = ({
  setShowEditWorkGroupModal,
  showEditWorkGroupModal,
  workGroup,
}) => {
  const { t } = useTranslation();

  const initialHourlyRate = workGroup.settings.find(
    (setting: any) => setting.key === "HourlyPay"
  );
  const initialMonthlyRate = workGroup.settings.find(
    (setting: any) => setting.key === "MonthlyPay"
  );

  const [, , alertDispatch] = useAlertStore();

  const [hourlyRate, setHourlyRate] = useState<string>(
    initialHourlyRate?.value ? initialHourlyRate.value : "0"
  );
  const [monthlyRate, setMonthlyRate] = useState<string>(
    initialMonthlyRate?.value ? initialMonthlyRate.value : "0"
  );
  const [companyState, ,] = useCompanyStore();
  const [changedSettings, setChangedSettings] =
    useState<
      { key: "HourlyPay" | "MonthlyPay"; value: string | undefined }[]
    >();
  const [, workgroupActions] = useWorkgroupStore();
  const [workgroupName, setWorkgroupName] = useState(workGroup.name);

  const handleUpdate = () => {
    if (workGroup.name !== workgroupName && companyState.company) {
      workgroupActions
        .updateWorkGroupName(
          companyState.company.id,
          workGroup.id,
          workgroupName
        )
        .then(() => {
          if (!changedSettings) {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("WorkGroup.Updated"),
                message: t("WorkGroup.WorkGroupHasBeenUpdated"),
              },
            });
            setShowEditWorkGroupModal(false);
          }
        })
        .catch((e: any) => {
          console.log("error when editing workgroup");
        });
    }
    changedSettings?.forEach((setting) => {
      if (companyState.company && setting.value !== undefined) {
        workgroupActions
          .updateWorkGroup(
            companyState.company.id,
            workGroup.id,
            setting.key,
            setting.value
          )
          .then(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("WorkGroup.Updated"),
                message: t("WorkGroup.WorkGroupHasBeenUpdated"),
              },
            });
            setShowEditWorkGroupModal(false);
          })
          .catch((e: any) => {
            console.log("error when editing workgroup");
          });
      }
    });
  };

  useEffect(() => {
    if (initialHourlyRate?.value !== hourlyRate) {
      setChangedSettings((prevSettings) => {
        const updatedSettings = prevSettings ? [...prevSettings] : [];
        const index = updatedSettings.findIndex(
          (setting) => setting.key === "HourlyPay"
        );

        if (index !== -1) {
          updatedSettings[index].value = hourlyRate;
        } else {
          updatedSettings.push({ key: "HourlyPay", value: hourlyRate });
        }

        return updatedSettings;
      });
    }
  }, [hourlyRate]);

  useEffect(() => {
    if (initialMonthlyRate?.value !== monthlyRate) {
      setChangedSettings((prevSettings) => {
        const updatedSettings = prevSettings ? [...prevSettings] : [];
        const index = updatedSettings.findIndex(
          (setting) => setting.key === "MonthlyPay"
        );

        if (index !== -1) {
          updatedSettings[index].value = monthlyRate;
        } else {
          updatedSettings.push({ key: "MonthlyPay", value: monthlyRate });
        }

        return updatedSettings;
      });
    }
  }, [monthlyRate]);

  return (
    <>
      <Modal
        open={showEditWorkGroupModal}
        onClose={() => {
          setShowEditWorkGroupModal(false);
        }}
      >
        <ModalBody>
          <LinkWrapper>
            <CustomIcon
              color={Color.LighterDestructive}
              onClick={() => {
                setShowEditWorkGroupModal(false);
              }}
              name={"cross"}
            >
              {t("General.Close")}
            </CustomIcon>
          </LinkWrapper>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.PitchBlack}
            fontSize={FontSize.H3}
          >
            {t("WorkGroup.SettingsFor")} {workGroup.name}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            color={Color.PitchBlack}
            style={{ marginBottom: 10 }}
          >
            {t("WorkGroup.OnlyYourCompanyCanSeeThisInfo")}
          </Text>
          <Text
            style={{ marginTop: 10, marginBottom: 0 }}
            fontSize={FontSize.Small}
          >
            {t("WorkGroup.Name")}
          </Text>
          <TextInput
            style={{ marginTop: 10 }}
            value={workgroupName}
            onChange={(e: any) => {
              setWorkgroupName(e.currentTarget.value);
            }}
            maxLength={50}
          />
          <WorkGroupRate
            monthlyRate={monthlyRate}
            setMonthlyRate={setMonthlyRate}
            hourlyRate={hourlyRate}
            setHourlyRate={setHourlyRate}
          />
          <ButtonStyled
            style={{ marginTop: 40 }}
            backgroundColor={Color.ModernGreen}
            onClick={() => handleUpdate()}
          >
            {t("WorkGroup.Save")}
          </ButtonStyled>
        </ModalBody>
      </Modal>
    </>
  );
};
