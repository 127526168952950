import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChronoUnit, Instant, LocalDateTime } from "@js-joda/core";
import { CompanyGigDto, GigType } from "model/Gig";
import { ShiftDto } from "model/Shift";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { addLeadingZero, calculateBreakTime, getWorkHours } from "utils/utils";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import { ShiftsScrollBar } from "./gigDetail.styled";
import {
  ShiftUl,
  DropDownRow,
  Row,
  TimeAndDateContainer,
  VerticalDivider,
  TotalTimeContainer,
  TotalBrakeContainer,
  Container,
} from "./gigDetail.styled";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { IconCircularContainer } from "../dashboard/dashboard.styled";

type Props = {
  gigData: CompanyGigDto;
  isModalOpen: boolean;
};

export const GigDetailShifts: React.FC<Props> = ({ gigData, isModalOpen }) => {
  const { t } = useTranslation();
  const [shiftsOpen, setShiftsOpen] = useState(isModalOpen);
  const history = useHistory();
  const [hoverTimeReports, setHoverTimeReports] = useState(false);

  const renderShift = (shift: ShiftDto, index: number) => {
    const startTime = LocalDateTime.ofInstant(Instant.parse(shift.startTime));
    const endTime = LocalDateTime.ofInstant(Instant.parse(shift.endTime));

    const shiftMinutes = startTime.until(endTime, ChronoUnit.MINUTES);
    const shiftHours = Math.floor(shiftMinutes / 60);

    const shiftTime = `${shiftHours ? shiftHours + "h" : ""} ${
      shiftMinutes % 60 ? (shiftMinutes % 60) + "min" : ""
    }`;

    const breakTimeStart =
      shift.breakStartTime &&
      LocalDateTime.ofInstant(
        Instant.parse(shift.breakStartTime) //change to start break
      );
    const breakTimeEnd =
      shift.breakEndTime &&
      LocalDateTime.ofInstant(Instant.parse(shift.breakEndTime));

    const breakTimeStartString =
      breakTimeStart &&
      breakTimeStart.toLocalTime().truncatedTo(ChronoUnit.MINUTES).toString();

    const breakTimeEndString =
      breakTimeEnd &&
      breakTimeEnd.toLocalTime().truncatedTo(ChronoUnit.MINUTES).toString();

    return (
      <>
        <Row key={shift.id}>
          <TimeAndDateContainer>
            <Text
              color={Color.MidnightBlue}
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.Standard}
              style={{ margin: 0, padding: 0 }}
            >
              {startTime.dayOfMonth()}/{startTime.monthValue()}
            </Text>
            <Text color={Color.SeaBlue600}>
              {`${addLeadingZero(startTime.hour())}:${addLeadingZero(
                startTime.minute()
              )} - ${addLeadingZero(endTime.hour())}:${addLeadingZero(
                endTime.minute()
              )}`}
            </Text>
          </TimeAndDateContainer>
          <VerticalDivider />
          <TotalTimeContainer>
            <Text
              style={{ margin: 0, padding: 0 }}
              fontSize={FontSize.Small}
              color={Color.SeaBlue600}
            >
              {t("GigsCompany.TotalTime")}
            </Text>
            <Text
              color={Color.MidnightBlue}
              fontFamily={FontFamily.MontserratRegular}
            >
              {shiftTime}
            </Text>
          </TotalTimeContainer>
          <TotalBrakeContainer>
            <Text
              style={{ margin: 0, padding: 0 }}
              fontSize={FontSize.Small}
              color={Color.SeaBlue600}
            >{`${t("GigsWorker.MinBreak")}${":"}`}</Text>
            <Text>
              {breakTimeStartString && breakTimeEndString
                ? `${calculateBreakTime(
                    breakTimeStartString,
                    breakTimeEndString,
                    moment(
                      LocalDateTime.ofInstant(
                        Instant.parse(shift.startTime)
                      ).toString()
                    ),
                    moment(
                      LocalDateTime.ofInstant(
                        Instant.parse(shift.endTime)
                      ).toString()
                    )
                  )} ${t("GigsWorker.MinutesUnpaidBreak")}`
                : "0" + t("GigsWorker.MinutesUnpaidBreak")}
            </Text>
          </TotalBrakeContainer>
        </Row>
      </>
    );
  };

  return (
    <>
      {!isModalOpen ? (
        <DropDownRow
          onClick={() => {
            setShiftsOpen(!shiftsOpen);
          }}
        >
          <Text fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.Large}>
            {t("GigsCompany.Shifts")} ({gigData.shifts.length}
          {"st"} - {t("GigsCompany.Total")} {""}
          {getWorkHours(t, ...gigData.shifts)})
          </Text>
          <CustomIcon
            name={shiftsOpen ? "upward_arrow" : "downward_arrow"}
            size="24px"
            color={Color.BurntSienna}
            style={{ marginTop: 10 }}
          />
        </DropDownRow>
      ) : (
          <Text fontFamily={FontFamily.MontserratSemiBold}
          fontSize={FontSize.Large}>
            {t("GigsCompany.Shifts")} ({gigData.shifts.length} {"st"})
          </Text>
         
      )}
      {shiftsOpen && (
        <>
          <Row>
            <Text
              fontSize={FontSize.Standard}
              fontFamily={FontFamily.MontserratRegular}
            >
              {t("GigsCompany.TotalTime")}
            </Text>
            <Text fontSize={FontSize.Standard}>
              {getWorkHours(t, ...gigData.shifts)}
            </Text>
          </Row>
          <Row>
            <Text
              fontSize={FontSize.Standard}
              fontFamily={FontFamily.MontserratRegular}
            >
              {t("GigsCompany.Type")}
            </Text>
            <Text fontSize={FontSize.Standard}>
              {gigData.type == GigType.WORKPOOL
                ? t(`GigsCompany.WorkGroup`)
                : t(`GigsCompany.${gigData.type}`)}
            </Text>
          </Row>
          <Row>
            <Container>
              <Text
                fontSize={FontSize.Standard}
                fontFamily={FontFamily.MontserratSemiBold}
              >
                {t("GigsCompany.Shifts")}
              </Text>
              <IconCircularContainer
                onClick={() => {
                  history.push(
                    `/company/time-reports/${gigData.id}?gig=${gigData.role}`
                  );
                }}
                isAccept
                isSmall
                style={{ marginTop: 10, marginLeft: 10 }}
                onMouseEnter={() => setHoverTimeReports(true)}
                onMouseLeave={() => setHoverTimeReports(false)}
              >
                <CustomIcon
                  name="clock"
                  size="20px"
                  color={Color.White}
                  padding="2px"
                />
              </IconCircularContainer>
              {hoverTimeReports && (
                <Text
                  fontSize={FontSize.Small}
                  color={Color.Black}
                  style={{
                    marginLeft: 10,
                    backgroundColor: Color.LightGrey03,
                    padding: 4,
                    borderRadius: 4,
                  }}
                >
                  {t("CompanyTimeReport.TimeReports")}
                </Text>
              )}
            </Container>
            <Text fontSize={FontSize.Standard}>{}</Text>
          </Row>
          <ShiftsScrollBar itemCount={gigData.shifts.length}>
            <ShiftUl>
              {gigData.shifts.map((shift, index) => {
                return renderShift(shift, index);
              })}
            </ShiftUl>
          </ShiftsScrollBar>
        </>
      )}
    </>
  );
};
