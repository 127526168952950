import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { CustomLink, H3 } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import {
  ButtonContainer,
  ButtonStyled,
  DashBoardCell,
  DashboardCellWeekContent,
  DashBoardColumn,
  DashboardContainer,
} from "./dashboard.styled";
import moment from "moment";
import { DashboardWeek } from "./week/dashboardWeek.component";
import { Modal } from "@material-ui/core";
import { SaveGigForm } from "../saveGig/saveGigForm/saveGigForm.component";
import { CreateGigModalBody } from "../calendar/weeklyCalendar.styled";
import { DashboardApplications } from "./week/dashboardApplications.component";
import { DashboardTimeReports } from "./week/dashboardTimeReports.component";
import { DashboardLatestRatings } from "./week/dashboardLatestRatings.component";
import { GigSummaryModal } from "../gigDetail/gigSummaryModal.component";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { Filter, FilterType, GroupType } from "model/Calendar";

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [createGigModal, setCreateGigModal] = useState(false);
  const [gigSummaryModalOpen, setGigSummaryModalOpen] = useState(false);
  const [createdGigId, setCreatedGigId] = useState<number | null>(null);
  const [, calendarActions] = useCalendarStore();
  const [companyState] = useCompanyStore();
  const [filter, setFilter] = useState<Filter[]>([
    {
      filterId: -1,
      groupType: GroupType.NONE,
      filterType: FilterType.NONE,
    },
  ]);

  const goToWeek = () => {
    localStorage.setItem("CalendarDate", moment().format("YYYY-MM-DD"));
    history.push("./calendar");
  };

  return (
    <div style={{ marginBottom: 100 }}>
      <ButtonContainer>
        <ButtonStyled
          onClick={() => setCreateGigModal(true)}
          backgroundColor={Color.ModernGreen}
        >
          {t("GigsCompany.CreateGig")}
        </ButtonStyled>
      </ButtonContainer>
      <DashboardContainer>
        <DashBoardColumn>
          <DashboardTimeReports />
          <DashboardApplications />
        </DashBoardColumn>
        <DashBoardColumn>
          <DashBoardCell>
            <CustomLink
              color={Color.MidnightBlue}
              fontSize={FontSize.H3}
              fontFamily={FontFamily.MontserratRegular}
              onClick={() => goToWeek()}
            >
              {t("CalendarCompany.Week")} {moment().week()}
            </CustomLink>
            <DashboardCellWeekContent>
              <DashboardWeek />
            </DashboardCellWeekContent>
          </DashBoardCell>
          <DashboardLatestRatings />
        </DashBoardColumn>
      </DashboardContainer>
      <Modal
        open={createGigModal}
        onClose={() => {
          setCreateGigModal(false);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
          maxWidth: '85%'
        }}
      >
        <CreateGigModalBody>
          <H3>{t("CalendarCompany.CreateGig")}</H3>
          <SaveGigForm
            setShowCreateGigModal={setCreateGigModal}
            onCreateSuccess={(gigId: number) => {
              setCreateGigModal(false);
              setCreatedGigId(gigId);
              setGigSummaryModalOpen(true);
              calendarActions.updateWeeklyCalendarContext(
                t,
                companyState,
                "UPDATE_OVERVIEW_WEEK_CALENDAR_V2",
                filter
              );
            }}
          />
        </CreateGigModalBody>
      </Modal>
      <GigSummaryModal
        gigId={createdGigId}
        isOpen={gigSummaryModalOpen}
        onClose={() => setGigSummaryModalOpen(false)}
      />
    </div>
  );
};
