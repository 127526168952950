import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { H3, Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { LoaderProgress } from "components/icon/loaderProgress.component";
import moment from "moment";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import {
  CircularProgressWrapper,
  CreateGigModalBody,
  GigHeader,
  ModalBody,
  WeekDay,
} from "../../calendar/weeklyCalendar.styled";
import { CircularProgress, Modal } from "@material-ui/core";
import { SaveGigForm } from "../../saveGig/saveGigForm/saveGigForm.component";
import {
  CalendarHeaderGrid,
  CalenderDot,
  HeaderContainer,
} from "./dashboardWeek.styled";
import { Filter, FilterType, GroupType } from "model/Calendar";
import { WeeklyCalendarBlock } from "../../calendar/calendarGig/weeklyCalendarBlock.component";

type CurrentDate = {
  date: moment.Moment;
  weekDay: string;
};
export const DashboardWeek: React.FC = () => {
  const [companyState] = useCompanyStore();
  const [calendarState, calendarActions] = useCalendarStore();

  const { t } = useTranslation();
  const weekdayList = [
    t("WeekDays.MONDAYSHORT"),
    t("WeekDays.TUESDAYSHORT"),
    t("WeekDays.WEDNESDAYSHORT"),
    t("WeekDays.THURSDAYSHORT"),
    t("WeekDays.FRIDAYSHORT"),
    t("WeekDays.SATURDAYSHORT"),
    t("WeekDays.SUNDAYSHORT"),
  ];
  const today = moment();
  const [currentDates, setCurrentDates] = useState<CurrentDate[]>([]);
  const [showCreateGigModal, setShowCreateGigModal] = useState(false);
  const [filter, setFilter] = useState<Filter[]>([
    {
      filterId: -1,
      groupType: GroupType.NONE,
      filterType: FilterType.NONE,
    },
  ]);

  useEffect(() => {
    calendarActions.updateWeeklyCalendarContext(
      t,
      companyState,
      "UPDATE_OVERVIEW_WEEK_CALENDAR_V2",
      filter
    );
  }, [companyState.company]);

  useEffect(() => {
    let firstDayOfWeek = moment().startOf("isoWeek");
    let dates: CurrentDate[] = [];
    for (let x = 0; x < 7; x++) {
      dates.push({
        date: moment(firstDayOfWeek).add(x, "days"),
        weekDay: weekdayList[x],
      });
    }
    setCurrentDates(dates);
  }, []);

  return (
    <>
      <>
        <HeaderContainer>
          <CalendarHeaderGrid>
            <GigHeader />
            {currentDates.map((date: CurrentDate) => (
              <WeekDay
                key={date.weekDay}
                future={
                  date.date >= moment().set({ hour: 0, minutes: 0, seconds: 0 })
                    ? true
                    : false
                }
                today={
                  date.date.format("DD-MM-YYYY") === today.format("DD-MM-YYYY")
                    ? true
                    : false
                }
              >
                <Text
                  fontSize={FontSize.Small}
                  color={Color.MidnightBlue}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {date.weekDay}
                </Text>
                <Text fontSize={FontSize.Small} color={Color.MidnightBlue}>
                  {moment(date.date).format("D/M")}
                </Text>
                {date.date.format("DD-MM-YYYY") ===
                  today.format("DD-MM-YYYY") && <CalenderDot />}
              </WeekDay>
            ))}
          </CalendarHeaderGrid>
        </HeaderContainer>

        {!calendarState.isLoading ? (
          <WeeklyCalendarBlock dashboard compactView filter={filter} />
        ) : (
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        )}

        <Modal
          open={showCreateGigModal}
          onClose={() => {
            setShowCreateGigModal(false);
          }}
        >
          <CreateGigModalBody>
            <H3>{t("CalendarCompany.CreateGig")}</H3>
            <SaveGigForm setShowCreateGigModal={setShowCreateGigModal} />
          </CreateGigModalBody>
        </Modal>
      </>
      {calendarState.isLoading && <LoaderProgress />}
    </>
  );
};
