import React, { useEffect, useState } from "react";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { LinkWrapper, ModalBody } from "./workgroup.styled";
import { WorkGroupRate } from "./workgroupRate.component";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CustomIcon } from "components/icon/customIcon.component";
import { useWorkgroupStore } from "web-apps/company/stores/workgroupStore/workgroupStore";
import { WorkGroupWorker } from "model/Workgroup";
import { useAlertStore } from "stores/alertStore/alertStore";

type Props = {
  setShowEditWorkerModal: React.Dispatch<React.SetStateAction<boolean>>;
  showEditWorkerModal: boolean;
  worker: WorkGroupWorker;
};

export const WorkGroupEditWorkerModal: React.FC<Props> = ({
  setShowEditWorkerModal,
  showEditWorkerModal,
  worker,
}) => {
  const { t } = useTranslation();
  const initialHourlyRate = worker.settings.find(
    (setting) => setting.key === "HourlyPay"
  );
  const initialMonthlyRate = worker.settings.find(
    (setting) => setting.key === "MonthlyPay"
  );

  const [hourlyRate, setHourlyRate] = useState<string>(
    initialHourlyRate ? initialHourlyRate.value : "0"
  );
  const [monthlyRate, setMonthlyRate] = useState<string>(
    initialMonthlyRate ? initialMonthlyRate.value : "0"
  );
  const [companyState, ,] = useCompanyStore();
  const [, , alertDispatch] = useAlertStore();
  const [changedSettings, setChangedSettings] =
    useState<
      { key: "HourlyPay" | "MonthlyPay"; value: string | undefined }[]
    >();
  const [, workgroupActions] = useWorkgroupStore();

  const handleUpdate = () => {
    changedSettings?.forEach((setting) => {
      if (companyState.company && setting.value !== undefined) {
        workgroupActions
          .updateWorkerInWorkGroup(
            companyState.company.id,
            worker.workGroupId,
            worker.id,
            setting.key,
            setting.value
          )
          .then(() => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("WorkGroup.Updated"),
                message: t("WorkGroup.WorkerHasBeenUpdated"),
              },
            });
            setShowEditWorkerModal(false);
          })
          .catch(() => {
            console.log("error when trying to update worker");
          });
      }
    });
  };

  useEffect(() => {
    if (initialHourlyRate?.value !== hourlyRate) {
      setChangedSettings((prevSettings) => {
        const updatedSettings = prevSettings ? [...prevSettings] : [];
        const index = updatedSettings.findIndex(
          (setting) => setting.key === "HourlyPay"
        );

        if (index !== -1) {
          updatedSettings[index].value = hourlyRate;
        } else {
          updatedSettings.push({ key: "HourlyPay", value: hourlyRate });
        }

        return updatedSettings;
      });
    }
  }, [hourlyRate]);

  useEffect(() => {
    if (initialMonthlyRate?.value !== monthlyRate) {
      setChangedSettings((prevSettings) => {
        const updatedSettings = prevSettings ? [...prevSettings] : [];
        const index = updatedSettings.findIndex(
          (setting) => setting.key === "MonthlyPay"
        );

        if (index !== -1) {
          updatedSettings[index].value = monthlyRate;
        } else {
          updatedSettings.push({ key: "MonthlyPay", value: monthlyRate });
        }

        return updatedSettings;
      });
    }
  }, [monthlyRate]);

  return (
    <>
      <Modal
        open={showEditWorkerModal}
        onClose={() => {
          setShowEditWorkerModal(false);
        }}
      >
        <ModalBody>
          <LinkWrapper>
            <CustomIcon
              color={Color.LighterDestructive}
              onClick={() => {
                setShowEditWorkerModal(false);
              }}
              name={"cross"}
            >
              {t("General.Close")}
            </CustomIcon>
          </LinkWrapper>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.PitchBlack}
            fontSize={FontSize.H3}
          >
            {t("WorkGroup.SettingsFor")} {worker.firstName} {worker.lastName}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            color={Color.PitchBlack}
            style={{ marginBottom: 10 }}
          >
            {t("WorkGroup.OnlyYourCompanyCanSeeThisInfo")}
          </Text>
          <WorkGroupRate
            monthlyRate={monthlyRate}
            setMonthlyRate={setMonthlyRate}
            hourlyRate={hourlyRate}
            setHourlyRate={setHourlyRate}
          />
          <ButtonStyled
            style={{ marginTop: 40 }}
            backgroundColor={Color.ModernGreen}
            onClick={() => {
              handleUpdate();
            }}
          >
            {t("WorkGroup.Save")}
          </ButtonStyled>
        </ModalBody>
      </Modal>
    </>
  );
};
