import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";
import { ChronoUnit, Instant, LocalDateTime } from "@js-joda/core";
import { CircularProgress } from "@material-ui/core";
import { useAlertStore } from "stores/alertStore/alertStore";
import {
  TimeReportDto,
  TimeReportResolution,
  TimeReportStatus,
} from "model/TimeReport";
import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { BannerComponent } from "components/banners/banner.component";
import { LabelStyled, TextArea } from "components/form/inputs/inputs.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { Text } from "components/Typography/text.styled";
import {
  ModalBody,
  FlexRow,
  ButtonContainer,
  LinkWrapper,
  RowWrapper,
  TotalCostWrapper,
  InfoCircle,
  InfoContainer,
  BreakInfoRow,
  DividerLine,
} from "./timeReportModal.styled";
import { GigCosts, PaymentMethod } from "model/Gig";
import {
  calculateBreakTime,
  getWorkHours,
  isWithinXMinutes,
  updateTimeReportInContext,
} from "utils/utils";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import { Api } from "services/api/api.service";
import SimflexityPayLogo from "images/logo/svg/simflexityPay-logo.svg";
import { Logo } from "components/nav/logo/logo.styled";
import moment from "moment";
import { getCostsTimeReports } from "web-apps/company/utils/utils";
import { CustomIcon } from "components/icon/customIcon.component";
type Props = {
  chosenTimeReportWithWorker: TimeReportDto;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRatingModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  calenderView?: boolean;
  triggerRefreshTimeReports?: React.Dispatch<React.SetStateAction<void>>;
};
const emptyCosts = {
  grossSalary: 0,
  obAmount: 0,
  socialFee: 0,
  simflexityFee: 0,
  totalCost: 0,
};

export const TimeReportModal: React.FC<Props> = ({
  chosenTimeReportWithWorker,
  modalOpen,
  setModalOpen,
  setRatingModalOpen,
  calenderView,
  triggerRefreshTimeReports,
}) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [timeReportState, timeReportActions, timeReportDispatch] =
    useTimeReportStore();
  const [companyState] = useCompanyStore();
  const [companyNotes, setCompanyNotes] = useState("");
  const [showFeeInfo, setShowFeeInfo] = useState(false);
  const [showBreakInfo, setShowBreakInfo] = useState(false);
  const [timeReportCost, setTimeReportCost] = useState<GigCosts>(emptyCosts);
  const [calendarState, calendarActions] = useCalendarStore();
  const [hoverOnCheckInTime, setHoverOnCheckInTime] = useState(false);
  const [hoverOnCheckOutTime, setHoverOnCheckOutTime] = useState(false);
  const [userNotesIsOpen, setUserNotesIsOpen] = useState(true);
  const [companyNotesIsOpen, setCompanyNotesIsOpen] = useState(false);

  const d = LocalDateTime.ofInstant(
    Instant.parse(chosenTimeReportWithWorker.startTime)
  );
  const date = `${t(
    `WeekDays.${d.dayOfWeek().name()}SHORT`
  )} ${d.dayOfMonth()}/${d.monthValue()}`;

  const startTime = LocalDateTime.ofInstant(
    Instant.parse(chosenTimeReportWithWorker.startTime)
  );
  const endTime = LocalDateTime.ofInstant(
    Instant.parse(chosenTimeReportWithWorker.endTime)
  );
  const startTimeStr = startTime
    .toLocalTime()
    .truncatedTo(ChronoUnit.MINUTES)
    .toString();

  const endTimeStr = endTime
    .toLocalTime()
    .truncatedTo(ChronoUnit.MINUTES)
    .toString();

  const breakTimeStart =
    chosenTimeReportWithWorker.breakStartTime &&
    LocalDateTime.ofInstant(
      Instant.parse(chosenTimeReportWithWorker.breakStartTime) //change to start break
    );
  const breakTimeEnd =
    chosenTimeReportWithWorker.breakEndTime &&
    LocalDateTime.ofInstant(
      Instant.parse(chosenTimeReportWithWorker.breakEndTime)
    );

  const breakTimeStartString =
    breakTimeStart &&
    breakTimeStart.toLocalTime().truncatedTo(ChronoUnit.MINUTES).toString();

  const breakTimeEndString =
    breakTimeEnd &&
    breakTimeEnd.toLocalTime().truncatedTo(ChronoUnit.MINUTES).toString();
  const userNotes = chosenTimeReportWithWorker.userNotes || "";

  const handleTimeReport = (
    response: TimeReportStatus.CLOSED | TimeReportStatus.REJECTED,
    resolution?: TimeReportResolution.WITHDRAWN
  ) => {
    const newTimeReport = {
      ...chosenTimeReportWithWorker,
      companyNotes,
      status: response,
      ...(response === TimeReportStatus.CLOSED && !resolution
        ? {
            resolution: TimeReportResolution.TIME_REPORTED,
          }
        : {
            resolution: TimeReportResolution.WITHDRAWN,
          }),
    };
    Api()
      .company.timeReport.updateGigTimeReport(
        chosenTimeReportWithWorker.companyId,
        chosenTimeReportWithWorker.gigId,
        newTimeReport
      )
      .then((res) => {
        if (!resolution) {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.Updated"),
              message: t("CompanyTimeReport.TimeReportHasBeenUpdated"),
            },
          });
        } else {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.Unbooked"),
              message: t("CompanyTimeReport.TimeReportHasBeenUnbooked"),
            },
          });
        }

        setModalOpen(false);

        timeReportDispatch({
          type: "UPDATE_UNHANDLED_TIME_REPORTS",
          payload: updateTimeReportInContext(
            timeReportState.unhandledTimeReports,
            "UPDATE_UNHANDLED_TIME_REPORTS",
            newTimeReport
          ),
        });
        timeReportDispatch({
          type: "UPDATE_MONTHLY_TIME_REPORTS",
          payload: updateTimeReportInContext(
            timeReportState.monthlyTimeReports,
            "UPDATE_MONTHLY_TIME_REPORTS",
            newTimeReport
          ),
        });
        timeReportDispatch({
          type: "UPDATE_GIG_TIME_REPORTS",
          payload: updateTimeReportInContext(
            timeReportState.gigTimeReports,
            "UPDATE_GIG_TIME_REPORTS",
            newTimeReport
          ),
        });
        timeReportDispatch({
          type: "UPDATE_UNHANDLED_TIME_REPORT_COUNT",
          payload: timeReportState.unhandledTimeReportCount - 1,
        });
        if (res.data.isLastTimeReport && !resolution) {
          setRatingModalOpen(true);
        } else {
          setRatingModalOpen(false);
        }
        if (calendarState.currentWeekV2.length > 0) {
          calendarActions.updateTimeReportCalendarContext(newTimeReport);
        }
      })
      .catch(() => {
        alertDispatch({
          type: "SHOW_ALERT",
          payload: {
            icon: "alert", // TODO: ADD SAME ICON AS IN APP
            title: t("Alerts.OhNo"),
            message: t("CompanyTimeReport.TimeReportCouldNotBeUpdated"),
          },
        });
      });
  };

  const handleSubmit = (
    response:
      | TimeReportStatus.CLOSED
      | TimeReportStatus.APPROVED
      | TimeReportStatus.REJECTED
  ) => {
    const newTimeReport = {
      ...chosenTimeReportWithWorker,
      companyNotes,
      status: response,
      ...(response === TimeReportStatus.CLOSED && {
        resolution: TimeReportResolution.TIME_REPORTED,
      }),
    };
    Api()
      .company.timeReport.updateGigTimeReport(
        chosenTimeReportWithWorker.companyId,
        chosenTimeReportWithWorker.gigId,
        newTimeReport
      )
      .then(async (res) => {
        timeReportActions
          .createPayoutForTimeReport(chosenTimeReportWithWorker)
          .then((res) => {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark", // TODO: ADD SAME ICON AS IN APP
                title: t("Alerts.Updated"),
                message: t("CompanyTimeReport.TimeReportHasBeenUpdated"),
              },
            });
          });
        setModalOpen(false);

        if(triggerRefreshTimeReports) {
          triggerRefreshTimeReports()
        } 

        timeReportDispatch({
          type: "UPDATE_UNHANDLED_TIME_REPORTS",
          payload: updateTimeReportInContext(
            timeReportState.unhandledTimeReports,
            "UPDATE_UNHANDLED_TIME_REPORTS",
            newTimeReport
          ),
        });
        timeReportDispatch({
          type: "UPDATE_UNHANDLED_TIME_REPORT_COUNT",
          payload: timeReportState.unhandledTimeReportCount - 1,
        });
        timeReportDispatch({
          type: "UPDATE_MONTHLY_TIME_REPORTS",
          payload: updateTimeReportInContext(
            timeReportState.monthlyTimeReports,
            "UPDATE_MONTHLY_TIME_REPORTS",
            newTimeReport
          ),
        });
        timeReportDispatch({
          type: "UPDATE_UNHANDLED_TIME_REPORT_COUNT",
          payload: timeReportState.unhandledTimeReportCount - 1,
        });
        if (res.data.isLastTimeReport) {
          setRatingModalOpen(true);
        } else {
          setRatingModalOpen(false);
        }
        if (calendarState.currentWeekV2.length > 0) {
          calendarActions.updateTimeReportCalendarContext(newTimeReport);
        }
      })
      .catch(() => {
        alertDispatch({
          type: "SHOW_ALERT",
          payload: {
            icon: "alert",
            title: t("Alerts.OhNo"),
            message: t("CompanyTimeReport.CouldNotCreatePayOut"),
          },
        });
      });
  };

  const renderBanner = (
    status: TimeReportStatus,
    resolution: TimeReportResolution
  ) => {
    switch (status) {
      case TimeReportStatus.CLOSED: {
        return (
          <>
            <BannerComponent icon="check" backgroundColor={Color.SeaBlue200}>
              <Text
                fontSize={FontSize.Large}
                fontFamily={FontFamily.MontserratSemiBold}
                color={Color.Destructive}
              >
                {resolution === TimeReportResolution.CANCELLED
                  ? t(`CompanyTimeReport.Closed`)
                  : t(`CompanyTimeReport.${resolution}`)}
              </Text>
              <Text>
                {resolution === TimeReportResolution.TIME_REPORTED
                  ? t("CompanyTimeReport.TimeReportClosedReported")
                  : resolution === TimeReportResolution.CANCELLED ||
                    resolution === TimeReportResolution.SICKLEAVE ||
                    resolution === TimeReportResolution.SICKLEAVECHILD ||
                    resolution === TimeReportResolution.PARENTALLEAVE ||
                    resolution === TimeReportResolution.LEAVEOFABSENCE ||
                    resolution === TimeReportResolution.OVERTIMECOMPENSATION ||
                    resolution === TimeReportResolution.FUNERALLEAVE ||
                    resolution === TimeReportResolution.MILITARYSERVICE ||
                    resolution === TimeReportResolution.OTHER
                  ? t("CompanyTimeReport.TimeReportClosedCanceled")
                  : resolution === TimeReportResolution.WITHDRAWN
                  ? t("CompanyTimeReport.TimeReportClosedWithdrawn")
                  : resolution === TimeReportResolution.PAYONDEMAND
                  ? t("CompanyTimeReport.TimeReportPayOnDemand")
                  : t("CompanyTimeReport.TimeReportClosedPaid")}
              </Text>
            </BannerComponent>
          </>
        );
      }
      case TimeReportStatus.REJECTED: {
        return (
          <BannerComponent
            backgroundColor={Color.ChampagnePink}
            icon="cross"
            fill={Color.Destructive}
          >
            <Text
              fontSize={FontSize.Large}
              fontFamily={FontFamily.MontserratSemiBold}
              color={Color.Destructive}
            >
              {t("CompanyTimeReport.Rejected")}
            </Text>
            <Text>{t("CompanyTimeReport.TimeReportRejected")}</Text>
          </BannerComponent>
        );
      }
      case TimeReportStatus.PAID: {
        return (
          <BannerComponent
            icon="wallet"
            fill={Color.BurntSienna}
            backgroundColor={Color.Positive}
          >
            <Text
              fontSize={FontSize.Large}
              fontFamily={FontFamily.MontserratSemiBold}
              color={Color.BurntSienna}
            >
              {t("CompanyTimeReport.Paid")}
            </Text>
            <Text>{t("CompanyTimeReport.TimeReportPaid")}</Text>
          </BannerComponent>
        );
      }
      case TimeReportStatus.NEW: {
        return (
          <BannerComponent
            icon="clock"
            fill={Color.SeaBlue600}
            backgroundColor={Color.SeaBlue200}
          >
            <Text
              fontSize={FontSize.Large}
              fontFamily={FontFamily.MontserratSemiBold}
              color={Color.SeaBlue600}
            >
              {t("CompanyTimeReport.Unsubmitted")}
            </Text>
            <Text fontSize={FontSize.Small}>
              {t("CompanyTimeReport.TimeReportUnsubmitted", {
                worker: chosenTimeReportWithWorker.firstName,
              })}
            </Text>
          </BannerComponent>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  useEffect(() => {
    setCompanyNotes(chosenTimeReportWithWorker.companyNotes || "");
    if (chosenTimeReportWithWorker.hourlyRate && companyState.company?.id) {
      getCostsTimeReports(
        [chosenTimeReportWithWorker],
        companyState.company?.id
      ).then((costs) => setTimeReportCost(costs));
    }
  }, [chosenTimeReportWithWorker]);

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalBody>
        <LinkWrapper>
          <CustomIcon
            color={Color.LighterDestructive}
            onClick={() => {
              setModalOpen(false);
            }}
            name={"cross"}
          >
            {t("General.Close")}
          </CustomIcon>
        </LinkWrapper>
        <Text fontSize={FontSize.H3} fontFamily={FontFamily.MontserratSemiBold}>
          {`${chosenTimeReportWithWorker?.firstName} ${
            chosenTimeReportWithWorker?.lastName
          }'s ${t("CompanyTimeReport.TimeReport").toLowerCase()}`}
        </Text>
        {renderBanner(
          chosenTimeReportWithWorker.status,
          chosenTimeReportWithWorker.resolution
        )}
        <FlexRow>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.PitchBlack}
            fontSize={FontSize.H4}
          >
            {t("CompanyTimeReport.TotalTime")}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            fontSize={FontSize.H4}
            color={Color.PitchBlack}
          >
            {getWorkHours(t, ...[chosenTimeReportWithWorker])}
          </Text>
        </FlexRow>
        <FlexRow>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H4}
          >
            {t("CompanyTimeReport.Date")}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            fontSize={FontSize.H4}
          >
            {date}
          </Text>
        </FlexRow>
        <FlexRow>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H4}
          >
            {t("CompanyTimeReport.ShiftStart")}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            fontSize={FontSize.H4}
          >
            {startTimeStr}
          </Text>
        </FlexRow>
        <FlexRow>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H4}
          >
            {t("CompanyTimeReport.ShiftEnd")}
          </Text>
          <Text
            fontFamily={FontFamily.MontserratRegular}
            fontSize={FontSize.H4}
          >
            {endTimeStr}
          </Text>
        </FlexRow>
        {chosenTimeReportWithWorker.checkInTime && (
          <FlexRow>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.H4}
            >
              {t("CompanyTimeReport.CheckIn")}
            </Text>
            <Text
              fontFamily={FontFamily.MontserratRegular}
              fontSize={FontSize.H4}
              {...(chosenTimeReportWithWorker.checkInTime != null &&
                !isWithinXMinutes(
                  new Date(chosenTimeReportWithWorker.startTime),
                  new Date(chosenTimeReportWithWorker.checkInTime),
                  15
                ) && { color: Color.Destructive })}
              onMouseEnter={() => {
                setHoverOnCheckInTime(true);
              }}
              onMouseLeave={() => {
                setHoverOnCheckInTime(false);
              }}
            >
              {new Date(
                chosenTimeReportWithWorker.checkInTime
              ).toLocaleTimeString("sv-SE", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Text>
            {hoverOnCheckInTime &&
              !isWithinXMinutes(
                new Date(chosenTimeReportWithWorker.startTime),
                new Date(chosenTimeReportWithWorker.checkInTime),
                15
              ) && (
                <InfoContainer>
                  <Text
                    fontSize={FontSize.Small}
                    color={Color.SeaBlue600}
                    fontFamily={FontFamily.MontserratSemiBold}
                  >
                    {t(
                      "CompanyTimeReport.MoreThan15MinutesDifferenceStartTime"
                    )}
                  </Text>
                </InfoContainer>
              )}
          </FlexRow>
        )}
        {chosenTimeReportWithWorker.checkInTime && (
          <FlexRow>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.H4}
            >
              {t("CompanyTimeReport.CheckOut")}
            </Text>
            <Text
              fontFamily={FontFamily.MontserratRegular}
              fontSize={FontSize.H4}
              {...(chosenTimeReportWithWorker.checkOutTime != null &&
                !isWithinXMinutes(
                  new Date(chosenTimeReportWithWorker.endTime),
                  new Date(chosenTimeReportWithWorker.checkOutTime),
                  15
                ) && { color: Color.Destructive })}
              onMouseEnter={() => {
                setHoverOnCheckOutTime(true);
              }}
              onMouseLeave={() => {
                setHoverOnCheckOutTime(false);
              }}
            >
              {chosenTimeReportWithWorker.checkOutTime
                ? new Date(
                    chosenTimeReportWithWorker.checkOutTime
                  ).toLocaleTimeString("sv-SE", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : "-"}
            </Text>
            {chosenTimeReportWithWorker.checkOutTime &&
              !isWithinXMinutes(
                new Date(chosenTimeReportWithWorker.endTime),
                new Date(chosenTimeReportWithWorker.checkOutTime),
                15
              ) &&
              hoverOnCheckOutTime && (
                <InfoContainer>
                  <Text
                    fontSize={FontSize.Small}
                    color={Color.SeaBlue600}
                    fontFamily={FontFamily.MontserratSemiBold}
                  >
                    {t("CompanyTimeReport.MoreThan15MinutesDifferenceEndTime")}
                  </Text>
                </InfoContainer>
              )}
          </FlexRow>
        )}
        <FlexRow>
          <BreakInfoRow>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.H4}
            >
              {t("CompanyTimeReport.Break")}
            </Text>
            <InfoCircle
              onMouseEnter={() => setShowBreakInfo(true)}
              onMouseLeave={() => setShowBreakInfo(false)}
            >
              <Text
                fontFamily={FontFamily.LeagueSpartanBold}
                color={Color.White}
                padding
                fontSize={FontSize.Small}
              >
                i
              </Text>
            </InfoCircle>
          </BreakInfoRow>

          {showBreakInfo && (
            <InfoContainer>
              <Text
                fontSize={FontSize.Small}
                color={Color.SeaBlue600}
                fontFamily={FontFamily.MontserratSemiBold}
              >
                {t("CompanyTimeReport.BreakHelpText")}
              </Text>
            </InfoContainer>
          )}
          <Text
            fontFamily={FontFamily.MontserratRegular}
            fontSize={FontSize.H4}
          >
            {breakTimeEnd &&
            breakTimeStart &&
            breakTimeStartString &&
            breakTimeEndString
              ? `${breakTimeStartString} - ${breakTimeEndString}  (${calculateBreakTime(
                  breakTimeStartString,
                  breakTimeEndString,
                  moment(startTime.toString()),
                  moment(endTime.toString())
                )} min)`
              : `0 min`}
          </Text>
        </FlexRow>
        {userNotes !== "" && (
          <FlexRow onClick={() => setUserNotesIsOpen(!userNotesIsOpen)}>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.H4}
            >
              {`${chosenTimeReportWithWorker.firstName} 
            ${chosenTimeReportWithWorker.lastName}'s 
            ${t("CompanyTimeReport.Notes").toLowerCase()}`}
            </Text>
            {userNotesIsOpen && <CustomIcon name={"upward_arrow"}></CustomIcon>}
            {!userNotesIsOpen && (
              <CustomIcon name={"downward_arrow"}></CustomIcon>
            )}
          </FlexRow>
        )}
        {userNotes !== "" && userNotesIsOpen && (
          <Text
            fontFamily={FontFamily.MontserratRegular}
            fontSize={FontSize.H4}
          >
            {userNotes}
          </Text>
        )}
        <FlexRow onClick={() => setCompanyNotesIsOpen(!companyNotesIsOpen)}>
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.H4}
          >
            {t("CompanyTimeReport.CompanyNotes")}
          </Text>
          {companyNotesIsOpen && (
            <CustomIcon name={"upward_arrow"}></CustomIcon>
          )}
          {!companyNotesIsOpen && (
            <CustomIcon name={"downward_arrow"}></CustomIcon>
          )}
        </FlexRow>
        {companyNotesIsOpen && (
          <TextArea
            fontSize={FontSize.Large}
            rows={3}
            name="companyNotes"
            disabled={
              chosenTimeReportWithWorker.status !== TimeReportStatus.SUBMITTED
            }
            placeholder={t("CompanyTimeReport.NotesPlaceHolder")}
            value={companyNotes}
            onChange={(e) => {
              if (
                chosenTimeReportWithWorker.status === TimeReportStatus.SUBMITTED
              ) {
                setCompanyNotes(e.currentTarget.value);
              }
            }}
          />
        )}
        <div>
          <FlexRow style={{ marginBottom: "40px" }}>
            <Text
              fontFamily={FontFamily.MontserratSemiBold}
              fontSize={FontSize.H4}
            >
              {t("CompanyTimeReport.HourlyRate")}
            </Text>

            <Text
              fontFamily={FontFamily.MontserratRegular}
              fontSize={FontSize.H4}
            >
              {chosenTimeReportWithWorker.hourlyRate}
              kr
            </Text>
          </FlexRow>
          {!timeReportState.isLoading && (
            <TotalCostWrapper>
              <RowWrapper
                style={{
                  justifyContent: "flex-start",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <LabelStyled
                  fontFamily={FontFamily.MontserratSemiBold}
                  color={Color.BurntSienna}
                  fontSize={FontSize.H4}
                >
                  {t("CompanyTimeReport.TotalCost")}{" "}
                </LabelStyled>
                <InfoCircle
                  onMouseEnter={() => setShowFeeInfo(true)}
                  onMouseLeave={() => setShowFeeInfo(false)}
                >
                  <Text
                    fontFamily={FontFamily.MontserratBold}
                    color={Color.White}
                    padding
                    fontSize={FontSize.Small}
                  >
                    i
                  </Text>
                </InfoCircle>
              </RowWrapper>
              {showFeeInfo && (
                <Text fontSize={FontSize.Small}>
                  {t("CompanyTimeReport.FeeInfo")}
                </Text>
              )}

              {timeReportCost && (
                <>
                  <RowWrapper>
                    <Text
                      fontFamily={FontFamily.MontserratSemiBold}
                      color={Color.BurntSienna}
                    >
                      {t("CompanyTimeReport.GrossSalaryExcludingOB")}:
                    </Text>
                    <Text color={Color.BurntSienna}>
                      {timeReportCost.grossSalary.toFixed(2)}
                      kr
                    </Text>
                  </RowWrapper>
                  <DividerLine />
                  <RowWrapper>
                    <Text
                      fontFamily={FontFamily.MontserratSemiBold}
                      color={Color.BurntSienna}
                    >
                      {t("CompanyTimeReport.OB")}:
                    </Text>
                    <Text color={Color.BurntSienna}>
                      {timeReportCost.obAmount.toFixed(2)}
                      kr
                    </Text>
                  </RowWrapper>
                  <DividerLine />
                  <RowWrapper>
                    <Text
                      fontFamily={FontFamily.MontserratSemiBold}
                      color={Color.BurntSienna}
                    >
                      {t("CompanyTimeReport.SocialFees")}:
                    </Text>
                    <Text color={Color.BurntSienna}>
                      {timeReportCost.socialFee.toFixed(2)}
                      kr
                    </Text>
                  </RowWrapper>
                  {chosenTimeReportWithWorker.paymentType ===
                    PaymentMethod.SIMFLEXITY_PAY && (
                    <>
                      <DividerLine />
                      <RowWrapper style={{ marginTop: "20px" }}>
                        <Text
                          fontFamily={FontFamily.MontserratBold}
                          color={Color.BurntSienna}
                        >
                          {t("CompanyTimeReport.SimflexityFee")}:
                        </Text>
                        <Text
                          fontFamily={FontFamily.MontserratBold}
                          color={Color.BurntSienna}
                        >
                          {timeReportCost.simflexityFee.toFixed(2)}
                          kr
                        </Text>
                      </RowWrapper>
                      <DividerLine />
                    </>
                  )}
                  <RowWrapper>
                    <Text
                      fontFamily={FontFamily.MontserratSemiBold}
                      color={Color.BurntSienna}
                    >
                      {t("CompanyTimeReport.TotalCost")}{" "}
                      {t("CompanyTimeReport.ExcludingVAT")}:
                    </Text>
                    <Text
                      color={Color.BurntSienna}
                      fontFamily={FontFamily.MontserratBold}
                    >
                      {timeReportCost.totalCost.toFixed(2)}
                      kr
                    </Text>
                  </RowWrapper>
                </>
              )}
              {chosenTimeReportWithWorker.paymentType ===
                PaymentMethod.SIMFLEXITY_PAY && (
                <RowWrapper style={{ justifyContent: "center" }}>
                  <Logo
                    src={SimflexityPayLogo}
                    style={{
                      height: "20px",
                      width: "auto",
                      marginBottom: "10px",
                    }}
                    alt="logo"
                  />
                </RowWrapper>
              )}
            </TotalCostWrapper>
          )}
        </div>
        <ButtonContainer>
          {timeReportState.isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <ButtonStyled
                backgroundColor={Color.LighterDestructive}
                isCancel
                disabled={
                  chosenTimeReportWithWorker.status !==
                  TimeReportStatus.SUBMITTED
                }
                onClick={() => {
                  if (
                    chosenTimeReportWithWorker.status ===
                    TimeReportStatus.SUBMITTED
                  ) {
                    handleSubmit(TimeReportStatus.REJECTED);
                  }
                }}
              >
                {t("CompanyTimeReport.Reject")}
              </ButtonStyled>
              {chosenTimeReportWithWorker.paymentType !==
                PaymentMethod.SIMFLEXITY_PAY &&
                !timeReportState.isLoading && (
                  <ButtonStyled
                    backgroundColor={Color.ModernGreen}
                    disabled={
                      chosenTimeReportWithWorker.status !==
                      TimeReportStatus.SUBMITTED
                    }
                    onClick={() => {
                      if (
                        chosenTimeReportWithWorker.status ===
                        TimeReportStatus.SUBMITTED
                      ) {
                        handleTimeReport(TimeReportStatus.CLOSED);
                      }
                    }}
                  >
                    {t("CompanyTimeReport.Approve")}
                  </ButtonStyled>
                )}
              {chosenTimeReportWithWorker.paymentType ===
                PaymentMethod.SIMFLEXITY_PAY && (
                <ButtonStyled
                  backgroundColor={Color.ModernGreen}
                  disabled={
                    chosenTimeReportWithWorker.status !==
                    TimeReportStatus.SUBMITTED
                  }
                  onClick={() => {
                    if (
                      chosenTimeReportWithWorker.status ===
                      TimeReportStatus.SUBMITTED
                    ) {
                      handleSubmit(TimeReportStatus.APPROVED);
                    }
                  }}
                >
                  {t("CompanyTimeReport.Approve")}{" "}
                  {t("CompanyTimeReport.AndPay")}
                </ButtonStyled>
              )}
              {calenderView && (
                <ButtonStyled
                  backgroundColor={Color.LighterDestructive}
                  disabled={
                    chosenTimeReportWithWorker.status !== TimeReportStatus.NEW
                  }
                  onClick={() => {
                    if (TimeReportStatus.NEW) {
                      handleTimeReport(
                        TimeReportStatus.CLOSED,
                        TimeReportResolution.WITHDRAWN
                      );
                    }
                  }}
                >
                  {t("CompanyTimeReport.CancelShift")}
                </ButtonStyled>
              )}
            </>
          )}
        </ButtonContainer>
      </ModalBody>
    </Modal>
  );
};
