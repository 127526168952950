import React, { useEffect, useState } from "react";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Text } from "components/Typography/text.styled";
import { OfferedGigModalBody } from "./weeklyCalendar.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import {
  CompanyCalendarApplication,
  CompanyCalendarGigDto,
} from "model/Calendar";
import {
  ApplicationDto,
  ApplicationRequestDto,
  ApplicationResolution,
  ApplicationStatus,
} from "model/Application";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { Api } from "services/api/api.service";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";

type OfferedGigModalProps = {
  offeredModalOpen: boolean;
  setOfferedModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  offeredWorkerName: string;
  shiftData?: CompanyCalendarGigDto;
  offeredWorkerId?: number;
};

export const OfferedGigModal: React.FC<OfferedGigModalProps> = ({
  offeredModalOpen,
  setOfferedModalOpen,
  offeredWorkerName,
  shiftData,
  offeredWorkerId,
}) => {
  const { t } = useTranslation();
  const [companyState] = useCompanyStore();
  const [clickedOffer, setClickedOffer] =
    useState<CompanyCalendarApplication[]>();
  const [, applicationActions] = useApplicationStore();
  const [application, setApplication] = useState<ApplicationDto>();
  const [, , alertDispatch] = useAlertStore();

  useEffect(() => {
    if (shiftData) {
      const matchedApplications = shiftData.applications.filter(
        (app) =>
          app.worker.id === offeredWorkerId &&
          app.status !== ApplicationStatus.CLOSED &&
          app.resolution !== ApplicationResolution.WITHDRAWN
      );
      setClickedOffer(matchedApplications);
    }
  }, [shiftData, offeredWorkerId]);

  const fetchApplication = async () => {
    if (companyState.company?.id && clickedOffer) {
      try {
        const applicationRes =
          await Api().company.application.getApplicationById(
            companyState.company?.id,
            clickedOffer[0].id
          );
        setApplication(applicationRes.data);
      } catch (error) {
        console.error("Error fetching application:", error);
      }
    }
  };

  useEffect(() => {
    fetchApplication();
  }, [clickedOffer]);

  const withdrawOffer = () => {
    if (companyState.company && application) {
      const req: ApplicationRequestDto = {
        ...application,
        status: ApplicationStatus.CLOSED,
        resolution: ApplicationResolution.WITHDRAWN,
      };

      applicationActions
        .updateApplicationStatus(companyState.company.id, application.id, req)
        .then(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "checkmark",
              title: t("GigsCompany.Withdrawn"),
              message: t("GigsCompany.OfferWithdrawn"),
            },
          });
          setOfferedModalOpen(false);
        })
        .catch((err: any) => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "cross",
              title: t("Alerts.OhNo"),
              message: t("Alerts.SomethingWentWrong"),
            },
          });
        });
    }
  };

  return (
    <Modal
      open={offeredModalOpen}
      onClose={() => {
        setOfferedModalOpen(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <OfferedGigModalBody>
        <Text
          fontFamily={FontFamily.MontserratSemiBold}
          color={Color.PitchBlack}
          fontSize={FontSize.H3}
        >
          {t("CalendarCompany.Offered")}
        </Text>
        <Text
          fontFamily={FontFamily.MontserratRegular}
          color={Color.PitchBlack}
        >
          {offeredWorkerName + t("CalendarCompany.GigHasBeenOfferedToWorker")}
        </Text>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "40px",
          }}
        >
          <ButtonStyled onClick={() => setOfferedModalOpen(false)}>
            {t("CalendarCompany.Ok")}
          </ButtonStyled>
          <ButtonStyled
            backgroundColor={Color.LighterDestructive}
            isCancel
            onClick={() => {
              withdrawOffer();
            }}
          >
            {t("CompanyTimeReport.WithdrawOffer")}
          </ButtonStyled>
        </div>
      </OfferedGigModalBody>
    </Modal>
  );
};
