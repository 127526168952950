import styled from "styled-components";
import { Color } from "config/colors";

export const ApplicationRow = styled.div`
  width: 100%;
  display: grid;
  grid-template: repeat(2, auto) / auto repeat(2, auto) 1fr;
  border-bottom: 1px solid ${Color.SeaBlue200};
  align-items: center;
  transition: background-color 0.1s ease-in;

  div {
    grid-row: 1 / 3;
  }

  a {
    text-decoration: none;
  }

  p {
    margin: 10px;
  }

  & > *:nth-child(2) {
    grid-column: 2 / 5;
    margin: 10px 10px 0;
  }

  &:hover {
    background-color: ${Color.SeaBlue200};
    cursor: pointer;
  }

  @media (min-width: 768px) {
    grid-template: minmax(70px, auto) / auto 9fr repeat(3, 4fr);

    & > *:nth-child(2) {
      grid-column: 2 / 3;
      margin: 10px;
    }

    div {
      grid-row: 1 / 2;
    }
  }
`;

export const ListContainer = styled.div`
  margin-top: 20px;
`;

export const RowLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${Color.White};
  height: 100%;
  max-width: 440px;

  &:hover {
    cursor: pointer;
    background-color: ${Color.SeaBlue200};
  }
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  padding: 0 4px 0 4px;
`;

type CellProps = {
  pending?: boolean;
};

export const Cell = styled.div<CellProps>`
  background-color: ${Color.White};
  margin: 10px 0;
  padding: 15px;
  display: grid;
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  grid-template:
    "worker-info-a"
    "divider1"
    "gig-info-a"
    "divider2"
    "button-action-1"
    "divider3"
    "button-action-2"
    / 1fr;

  border-bottom: 1px solid ${Color.SeaBlue200};

  @media (min-width: 640px) {
    grid-template:
      "worker-info-a"
      "divider1"
      "gig-info-a"
      "divider2"
      "button-action-1"
      "divider3"
      "button-action-2"
      / 2fr 1fr;
  }

  @media (min-width: 970px) {
    grid-template: ${(props) =>
      props.pending
        ? `
        "worker-info-a divider1 gig-info-a divider2 button-action-1 divider3 button-action-2"
        / 2fr 1px 1fr 1px 0.2fr 1px 0.2fr
      `
        : `
        "worker-info-a divider1 gig-info-a divider2 button-action-1 divider3 button-action-2"
        / 1fr 1px 1fr 1px 0.2fr 1px 0.2fr
      `};
  }

  p {
    margin: 0;
  }
`;

export const GigInfoContainer = styled.div`
  margin-left: 10px;
  grid-area: gig-info-a;
`;

export const DividerLine3 = styled.div`
  display: none;
  background-color: ${Color.SeaBlue300};
  grid-area: divider3;

  @media (min-width: 970px) {
    display: unset;
  }
`;

export const WorkerInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  grid-area: worker-info-a;
`;

export const ButtonActionContainer1 = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  grid-area: button-action-1;
`;

export const ApplicationsCount = styled.div`
  background-color: ${Color.LighterYellow};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align:center;
`;

export const ButtonActionContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  grid-area: button-action-2;
`;

export const Row = styled.div`
  display: grid;
  grid-template: 1fr / 1.1fr 1fr 0.6fr;
  width: 100%;
  align-items: center;
  //background-color: ${Color.White};
  border-bottom: 1px solid ${Color.SeaBlue200};
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px 0 4px;
`;

export const TagRow = styled(ButtonsRow)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  max-width: 200px;
`;

export const ListWrapper = styled.div.attrs(
  (props: { loading: boolean }) => props
)`
  opacity: ${(props) => (props.loading ? "0.5" : "1.0")};
`;

export const CheckmarkContainer = styled.div`
  height: 40px;
  width: 40px;
  border: 3px solid ${Color.BurntSienna};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
