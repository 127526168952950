import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import "react-tooltip/dist/react-tooltip.css";
import { Text } from "components/Typography/text.styled";
import { CircularProgressWrapper } from "./weeklyCalendar.styled";
import { CircularProgress, Modal } from "@material-ui/core";
import { GigDetail } from "../gigDetail/gigDetail.component";
import { useGetGig } from "hooks/useGetGig";
import { CustomIcon } from "components/icon/customIcon.component";
import SimflexityPayLogo from "images/logo/svg/simflexityPay-logo.svg";
import {
  GigHeader,
  LinkWrapper,
  ModalBody,
  TagsContainer,
} from "../gigDetail/gigDetail.styled";
import { Instant, LocalDateTime } from "@js-joda/core";
import { WorkerWithUrl } from "model/Company";
import { GigType, PaymentMethod } from "model/Gig";
import { Logo } from "components/nav/logo/logo.styled";

type GigDetailsModalProps = {
  gigId: number;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalOpen: boolean;
  workerWithImageUrl?: WorkerWithUrl[];
};

export const GigDetailsModal: React.FC<GigDetailsModalProps> = ({
  gigId,
  setModalOpen,
  modalOpen,
}) => {
  const [gigData, isLoading, error, fetchGig] = useGetGig(gigId);
  const { t } = useTranslation();
  const [startTimeGig, setStartTimeGig] = useState<LocalDateTime>();
  const [endTimeGig, setEndTimeGig] = useState<LocalDateTime>();
  const now = LocalDateTime.now();
  const startTime = gigData && (gigData.shifts?.[0]?.startTime ?? "");
  const endTime =
    gigData && (gigData.shifts?.[gigData.shifts.length - 1]?.endTime ?? "");

  useEffect(() => {
    if (error) {
      setModalOpen(false);
    }
  }, [error]);

  useEffect(() => {
    if (
      gigData &&
      gigData.shifts[0] &&
      gigData.shifts[gigData.shifts.length - 1]
    ) {
      setStartTimeGig(
        LocalDateTime.ofInstant(Instant.parse(gigData.shifts[0].startTime))
      );
      setEndTimeGig(
        LocalDateTime.ofInstant(
          Instant.parse(gigData.shifts[gigData.shifts.length - 1].endTime)
        )
      );
    }
  }, [gigData]);

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalBody>
        {isLoading ? (
          <CircularProgressWrapper>
            <CircularProgress />
          </CircularProgressWrapper>
        ) : (
          <>
            <div>
              <LinkWrapper>
                <TagsContainer>
                  {startTime &&
                    endTime &&
                    Date.now() > Date.parse(startTime) &&
                    Date.now() < Date.parse(endTime) && (
                      <Text
                        fontSize={FontSize.Small}
                        color={Color.BurntSienna}
                        backgroundColor={Color.Positive}
                        padding
                        style={{
                          margin: 0,
                          lineHeight: FontSize.Small,
                          width: 70,
                          marginBottom: 10,
                        }}
                      >
                        {t("GigsCompany.Ongoing")}
                      </Text>
                    )}
                  {startTimeGig &&
                    now > startTimeGig &&
                    endTimeGig &&
                    now > endTimeGig && (
                      <Text
                        fontSize={FontSize.Small}
                        color={Color.Destructive}
                        backgroundColor={Color.LightWarning}
                        padding
                        style={{
                          margin: 0,
                          lineHeight: FontSize.Small,
                          width: 70,
                          marginBottom: 10,
                          textAlign: "center",
                        }}
                      >
                        {t("GigsCompany.Passed")}
                      </Text>
                    )}
                  <Text
                    fontSize={FontSize.Small}
                    color={Color.DarkGrey}
                    backgroundColor={Color.Disabled}
                    padding
                    style={{
                      margin: 0,
                      lineHeight: FontSize.Small,
                      width:
                        gigData?.type == GigType.STAFFING ||
                        gigData?.type == GigType.WORKPOOL
                          ? 90
                          : 60,
                      marginBottom: 10,
                      textAlign: "center",
                      marginLeft: 10,
                    }}
                  >
                    {gigData?.type == GigType.PUBLIC
                      ? t("GigsCompany.Public")
                      : gigData?.type == GigType.HIDDEN
                      ? t("GigsCompany.Hidden")
                      : gigData?.type == GigType.STAFFING
                      ? t("GigsCompany.Staffing")
                      : t("GigsCompany.WorkGroup")}
                  </Text>
                  {gigData?.paymentType == PaymentMethod.SIMFLEXITY_PAY && (
                    <Logo
                      src={SimflexityPayLogo}
                      style={{
                        height: "18px",
                        width: "auto",
                        marginLeft: 10,
                        marginTop: 2,
                      }}
                      alt="logo"
                    />
                  )}
                </TagsContainer>
                <CustomIcon
                  color={Color.LighterDestructive}
                  onClick={() => {
                    setModalOpen(false);
                  }}
                  name={"cross"}
                >
                  {t("General.Close")}
                </CustomIcon>
              </LinkWrapper>

              {gigData && (
                <GigHeader>
                  <Text
                    fontSize={FontSize.H3}
                    fontFamily={FontFamily.MontserratRegular}
                    style={{ margin: 0 }}
                  >
                    {gigData.role}
                  </Text>
                </GigHeader>
              )}
            </div>

            {gigData && (
              <GigDetail
                gigData={gigData}
                onGigClosed={() => fetchGig(gigData.id)}
                isModalOpen={false}
                shouldDefaultOpen={true}
              />
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
};
