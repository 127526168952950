import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import moment from "moment";
import { Text } from "components/Typography/text.styled";
import { CompanyCalendarGigDto, CompanyCalendarShift, Filter } from "model/Calendar";
import { ShiftCell } from "../weeklyCalendar.styled";
import { NotFilledGigModal } from "../notFilledGigModal";

type Props = {
  compactView: boolean;
  shift: CompanyCalendarShift;
  keyId: number;
  dashboard: boolean | undefined;
  gigData: CompanyCalendarGigDto;
  deleteShift: any;
  name: string;
  filter: Filter[];
};

export const NotFilledCalendarGig: React.FC<Props> = ({
  compactView,
  shift,
  keyId,
  dashboard,
  gigData,
  deleteShift,
  name,
  filter
}) => {
  const { t } = useTranslation();
  const [showNotFilledModal, setShowNotFilledModal] = useState(false);

  return (
    <>
      <ShiftCell
        key={keyId}
        backgroundColor={Color.LightGrey02}
        data-tooltip-id={"not-filled"}
        data-tooltip-content={t("CalendarCompany.NotFilled")}
        onClick={() => {
          setShowNotFilledModal(true);
        }}
      >
        {!compactView ? (
          <Text color={Color.White}>
            {`${moment(shift.startTime).format("HH:mm")} - ${moment(
              shift.endTime
            ).format("HH:mm")}`}
          </Text>
        ) : (
          <Text fontSize={FontSize.Small} color={Color.White}>{`${
            name.length > 6 && dashboard
              ? `${name.slice(0, 6)}...`
              : name
              ? `${name}`
              : dashboard && !name
              ? t(t("CalendarCompany.NotFilled")).slice(0, 7) + "..."
              : t(t("CalendarCompany.NotFilled"))
          }`}</Text>
        )}
        {!compactView ? (
          <Text color={Color.White}>{t("CalendarCompany.NotFilled")}</Text>
        ) : (
          <Tooltip
            id={"worker-name"}
            place="top"
            style={{
              backgroundColor: Color.White,
              color: Color.MidnightBlue,
              fontFamily: FontFamily.MontserratRegular,
              boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.1)",
            }}
          />
        )}
      </ShiftCell>
      <NotFilledGigModal
        showNotFilledModal={showNotFilledModal}
        setShowNotFilledModal={setShowNotFilledModal}
        deleteShift={(shiftId: number, gigsId: string) =>
          deleteShift(shiftId, gigsId)
        }
        shiftData={gigData}
        clickedShiftId={shift.id}
        filter={filter}
      />
    </>
  );
};
