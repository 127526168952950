import { CompanyCalendarItemsDtoV2, Filter, GroupType } from "model/Calendar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import {
  CalendarGrid,
  CalendarGridContainer,
  GroupRow,
} from "../weeklyCalendar.styled";
import { Text } from "components/Typography/text.styled";
import { CalendarGig } from "../calendarGig.component";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";

type Props = {
  compactView: boolean;
  filter: Filter[];
  dashboard?: boolean;
};

export const WeeklyCalendarBlock: React.FC<Props> = ({
  compactView,
  filter,
  dashboard,
}) => {
  const { t } = useTranslation();
  const [calendarState, ,] = useCalendarStore();
  const [calendarItems, setCalendarItems] = useState<
    CompanyCalendarItemsDtoV2[]
  >([]);
  const [noCalendarItemsWithGigs, setNoCalendarItemsWithGigs] = useState(false);

  useEffect(() => {
    if (!dashboard) {
      setCalendarItems(calendarState.currentWeekV2);
    } else {
      setCalendarItems(calendarState.overviewWeekV2);
    }
  }, [calendarState.currentWeekV2, calendarState.overviewWeekV2]);

  useEffect(() => {
    const allEmpty = calendarItems.every(
      (item) => Array.isArray(item.gigs) && item.gigs.length === 0
    );
    setNoCalendarItemsWithGigs(allEmpty);
  }, [calendarItems]);

  return (
    <>
      {!noCalendarItemsWithGigs ? (
        calendarItems.map(
          (gigData: CompanyCalendarItemsDtoV2, gigDataIndex: number) => {
            return gigData.gigs && gigData.gigs.length > 0 ? (
              <div key={gigDataIndex}>
                {gigData.groupType !== GroupType.NONE &&
                  gigData.groupName !== null && (
                    <GroupRow>
                      <Text
                        fontSize={FontSize.Standard}
                        fontFamily={FontFamily.MontserratSemiBold}
                        color={Color.White}
                      >
                        {gigData.groupName}
                      </Text>
                    </GroupRow>
                  )}
                <CalendarGridContainer dashboard={dashboard}>
                  <CalendarGrid>
                    {gigData.gigs.map((gig, index) => {
                      const calendarGigIndex =
                        (gigDataIndex + 1).toString() + index.toString();
                      return (
                        <CalendarGig
                          key={parseInt(calendarGigIndex)}
                          gigData={gig}
                          gigDataIndex={index}
                          compactView={compactView}
                          filter={filter}
                        />
                      );
                    })}
                  </CalendarGrid>
                </CalendarGridContainer>
              </div>
            ) : null;
          }
        )
      ) : (
        <Text
          style={{ textAlign: "center" }}
          fontSize={FontSize.Standard}
          color={Color.SeaBlue600}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("CalendarCompany.NoShiftsThisWeek")}
        </Text>
      )}
    </>
  );
};
